import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  Filter,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  CardActions,
  CreateButton,
  SelectInput,
  ReferenceInput
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";

export const ClientIcon = Icon;

export const ServerList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
        <TextField source="name" />
        <TextField source="ip" />
        <TextField source="domain" />
      <EditButton label="" /> 
    </Datagrid>
  </List>
);

export const ServerCreate = props => (
  <Create title="Create a Server" {...props} >
    <SimpleForm redirect="list">
        <TextInput source="name" />
        <TextInput source="ip" />
        <TextInput source="domain" />
        <TextInput source="cpu" />
        <TextInput source="ram" />
        <TextInput source="space" />
    </SimpleForm>
  </Create>
);

export const ServerEdit = props => (
  <Edit title="Edit Server" actions={<CardActions />} {...props}>
    <SimpleForm>
        <TextInput source="name" />
        <TextInput source="ip" />
        <TextInput source="domain" />
        <TextInput source="cpu" />
        <TextInput source="ram" />
        <TextInput source="space" />
    </SimpleForm>
  </Edit>
);