import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, SubmissionError, change } from 'redux-form';
import axios from 'axios';
import {Button, WithPermissions} from "react-admin";
import IconContentRemove from "@material-ui/icons/Delete";
import IconContentAdd from "@material-ui/icons/Add";
import IconContentSend from "@material-ui/icons/FileDownload";
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import download from 'downloadjs';
import { refreshView as refreshViewAction } from 'react-admin';


class Report extends Component {
   state = { 
     reports: [],
   };

    componentDidMount = () => {

    };

    downloadXls = () => {
        const { record, refreshView } = this.props;

        const authToken = localStorage.getItem('token');
        let url = '/api/invoices/downloadXls';

        axios({
            url: url,
            method: 'get',
            responseType: 'blob',
            headers: {
                Authorization: authToken,
                Accept: 'application/pdf',
            },
        }).then(response => {
            let filename = 'report-devs-'+record.name+'.xlsx';
            download(response.data, filename);
            refreshView();
        }).catch((error) => {
            console.log(error);
        });
    };

    render() {
        return (
            <div>
                <div style={{background:"white", borderRadius:"3px 3px 3px 3px", padding: "15px"}}>
                 Reports

                 <br/>
                 <br/>
                 <br/>

                    <Button label="Projects" onClick={() => this.save()}  style={{"color":"green"}}>
                        <IconContentSend style={{"color":"green"}} />
                    </Button>
                    <Button label="Employee" onClick={() => this.downloadXls()}  style={{"color":"green"}}>
                        <IconContentSend style={{"color":"green"}} />
                    </Button>
                    
                </div>
            </div>
        );
    }
}

Report.propTypes = {
    ...propTypes,
    push: PropTypes.func,
    theme: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};
 
Report.defaultProps = {
    theme: {},
};

export default connect(null, {
    push: pushAction,
})(Report);

