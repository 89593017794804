// React imports
import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

// React Admin imports
import { translate, MenuItemLink, WithPermissions } from "react-admin";

// Material Ui imports
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const styles = {
  root: {
    color: "#000"
  },
  active: {
    color: "#000"
  }
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

class MenuElem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  hasPermission = (allPermissions, givenPermissions) => {
    // show item if no explicit permission is set
    if (givenPermissions.length === 0) {
      console.log("no permissions given");
      return true;
    }
    let intersect = allPermissions.filter(function(n) {
      return givenPermissions.indexOf(n) > -1;
    });
    if (intersect.length > 0) {
      return true;
    }
    return false;
  };

  openClose = key => {
    if (typeof this.state[[`open${key}`]] == "undefined") {
      this.setState({ [`open${key}`]: false });
    } else {
      this.setState({ [`open${key}`]: !this.state[[`open${key}`]] });
    }
  };

  render() {
    const {
      items,
      onMenuClick,
      logout,
      translate,
      profile,
      classes
    } = this.props;
    return (
      <WithPermissions
        render={({ permissions }) => (
          <div>
            {items.map((item, index) =>
              permissions &&
              this.hasPermission(
                permissions ? permissions : [],
                item.permissions ? item.permissions : []
              ) ? (
                item.type === "divider" ? (
                  <Divider key={index} />
                ) : !item.items ? (
                  <LightTooltip
                    key={item.name + index}
                    title={translate(`resources.${item.name}.name`, {
                      smart_count: 2
                    })}
                    placement="right"
                  >
                    <MenuItemLink
                      to={`/${item.name}`}
                      primaryText={translate(`resources.${item.name}.name`, {
                        smart_count: 2
                      })}
                      leftIcon={item.icon}
                      onClick={onMenuClick}
                      classes={classes}
                    />
                  </LightTooltip>
                ) : (
                  <div key={item.name + index}>
                    <ListItem
                      button
                      onClick={() => this.openClose(item.name)}
                      style={{ marginLeft: "-8px" }}
                    >
                      {item.icon ? item.icon : null}
                      <ListItemText primary={translate(`menu.${item.name}`)} />
                      {typeof this.state[[`open${item.name}`]] == "undefined" ||
                      this.state[[`open${item.name}`]] ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </ListItem>
                    <Collapse
                      in={false}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MenuElem
                        items={item.items}
                        onMenuClick={onMenuClick}
                        translate={translate}
                        logout={logout}
                        profile={profile}
                      />
                    </Collapse>
                  </div>
                )
              ) : null
            )}
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  identity: state.identity
});

const enhance = compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps)
);

export default enhance(MenuElem);
