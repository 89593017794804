import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  Filter,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  CardActions,
  CreateButton,
  SelectInput,
  FunctionField,
  ReferenceInput
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";
import ExpandLess from "@material-ui/icons/ExpandLess";
import CommentButton from "../../inputs/CommentButton";


export const ClientIcon = Icon;

const ProjectTitle = ({ record }) => {
  return <span>Project {record ? `"${record.name}"` : ""}</span>;
};

const formatDepartment = dep => {

  if(dep==="3") return "Vzhod";
  if(dep==="2") return "Zahod";
  if(dep==="4") return "MKTG - VP";
  if(dep==="14") return "SEO";
  if(dep==="18") return "ADV";
  if(dep==="21") return "Content";
};

const TasksFilter = props => (
  <Filter {...props}>
     <SelectInput source="department" choices={[
          { id: '3', name: 'Vzhod' },
          { id: '2', name: 'Zahod' },
          { id: '4', name: 'MKTG - VP' },
          { id: '14', name: 'SEO' },
          { id: '18', name: 'ADV' },
          { id: '21', name: 'Content' },
      ]} />
       <ReferenceInput
            label="Project"
            sort={{ field: "id", order: "ASC" }}
            source="project.id"
            reference="projects"
            perPage={300}
            filterToQuery={searchText => ({ name: searchText })}
          allowEmpty
          >
          <AutocompleteInput optionText="name" />
      </ReferenceInput>   
      <ReferenceInput
              label="Client"
              sort={{ field: "id", order: "ASC" }}
              source="customer.id"
              reference="customers"
              filterToQuery={searchText => ({ name: searchText })}
              allowEmpty
            >
            <AutocompleteInput optionText="companyName" />
        </ReferenceInput>
  </Filter>
);


export const TasksList = props => (
  <List {...props} bulkActionButtons={false} filters={<TasksFilter />}>
    <Datagrid>
      <TextField source="type" label="Type" />
      <TextField source="name" />
      <TextField source="priority" label="priority" />
      <TextField source="status" label="Status" />
      <CommentButton />
      <EditButton label="" /> 
    </Datagrid>
  </List>
);

export const TasksCreate = props => (
  <Create title="Create a Note" {...props} >
    <SimpleForm redirect="list">
       <TextInput source="name" />
        <SelectInput source="type" choices={[
           { id: 'Bug', name: 'Bug' },
           { id: 'Task', name: 'Task' },
          ]} />
        <SelectInput source="priority" choices={[
          { id: 'Low', name: 'Low' },
          { id: 'Medium', name: 'Medium' },
          { id: 'High', name: 'High' },
         ]} />
        <SelectInput source="status" choices={[
          { id: 'TODO', name: 'TODO' },
          { id: 'in Progress', name: 'in Progress' },
          { id: 'in Customer Review', name: 'in Customer Review' },
          { id: 'Done', name: 'Done' },
         ]} />
    </SimpleForm>
  </Create>
);

export const TasksEdit = props => (
  <Edit title="Edit Note" actions={<CardActions />} {...props}>
    <SimpleForm>
    <TextInput source="name" />     
        <SelectInput source="type" choices={[
           { id: 'Bug', name: 'Bug' },
           { id: 'Task', name: 'Task' },
          ]} />   
       <SelectInput source="priority" choices={[
          { id: 'Low', name: 'Low' },
          { id: 'Medium', name: 'Medium' },
          { id: 'High', name: 'High' },
         ]} />
        <SelectInput source="status" choices={[
          { id: 'TODO', name: 'TODO' },
          { id: 'in Progress', name: 'in Progress' },
          { id: 'in Customer Review', name: 'in Customer Review' },
          { id: 'Done', name: 'Done' },
         ]} />
    </SimpleForm>

  </Edit>
);