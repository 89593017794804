// React imports
import React, { Component } from "react";
import { connect } from "react-redux";

// React Admin imports
import { Notification, setSidebarVisibility } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

// Other imports
import AppBar from "../partials/AppBar";
import Sidebar from "../partials/Sidebar";
import Menu from "../partials/Menu";

/* const CustomSidebar = props => <Sidebar size={240} {...props} />;
const CustomLayout = props => (
    <Layout appBar={AppBar} sidebar={CustomSidebar} {...props} />
); */

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    //backgroundColor: theme.palette.background.default,
    backgroundColor: "#868686",
    position: "relative"
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto"
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: "20px",
    marginTop: "0"
  }
});

class CustomLayout extends Component {
  componentWillMount() {
    this.props.setSidebarVisibility(true);
  }

  render() {
    const { children, classes, dashboard, logout, open, title } = this.props;

    return (
      <div className={classes.root} notification={Notification}>
        <AppBar title={title} open={open} logout={logout} />
        <main className={classes.contentWithSidebar}>
          <Sidebar>
            <Menu logout={logout} hasDashboard={!!dashboard} />
          </Sidebar>
          <div className={classes.content}>{children}</div>
        </main>
        <Notification />
      </div>
    );
  }
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });
export default connect(
  mapStateToProps,
  { setSidebarVisibility }
)(withStyles(styles)(CustomLayout));
