import { stringify } from "query-string";
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY
} from "react-admin";
import { invalidateData } from "../authProvider";

import "whatwg-fetch";

/**
 * Maps admin-on-rest queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=title,asc&page=0&size=20
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    //hack to use the same resource for events and competitions
    if (resource == "events") {
      resource = "competitions";
      params.filter = { ...params.filter, event: true };
    }

    let url = "";
    const options = {
      headers: new Headers({
        Accept: "application/json",
        authorization: localStorage.getItem("token")
      })
    };

    switch (type) {
      case "GET_LIST_COMPETITION": {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: field + "," + order.toLowerCase(),
          page: page - 1,
          size: perPage
        };
        url =
          `${apiUrl}/${resource}?${stringify(query)}` +
          "&filter=" +
          params.filter;
        break;
      }
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          sort: field + "," + order.toLowerCase(),
          page: page - 1,
          size: perPage
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        if (typeof params !== "undefined") {
          url = `${apiUrl}/${resource}/${params.id}`;
        } else {
          // hack to get stuff like /api/dashboard to work
          url = `${apiUrl}/${resource}`;
        }
        break;
      case GET_MANY: {
        url = `${apiUrl}/${resource}/many`;
        options.method = "POST";
        options.body = JSON.stringify(params.ids);
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          [params.target]: params.id,
          sort: field + "," + order.toLowerCase(),
          page: page - 1,
          size: perPage
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE_MANY:
        url = `${apiUrl}/${resource}/delete`;
        options.body = JSON.stringify(params.ids);
        options.method = "POST";
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}/delete`;
        options.body = JSON.stringify(params.data);
        options.method = "POST";
        break;
      case "UPLOAD":
        url = `${apiUrl}/${resource}`;
        options.body = params;
        options.method = "POST";
        break;

      case "DOWNLOAD":
        url = `${apiUrl}/${resource}/${params.id}`;
        options.headers = new Headers({
          authorization: localStorage.getItem("token")
        });
        options.method = "GET";
        break;

      case "EXPORT": {
        const query = { ...fetchUtils.flattenObject(params.filter) };

        if (params.pagination) {
          query.page = params.pagination.page - 1;
          query.size = params.pagination.perPage;
        }

        if (params.sort) {
          query.sort =
            params.sort.field + "," + params.sort.order.toLowerCase();
        }

        url = `${apiUrl}/${resource}?${stringify(query)}`;
        options.headers = new Headers({
          authorization: localStorage.getItem("token"),
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        options.method = "GET";
        break;
      }
      case "EXPORT_LIST_COMPETITION": {
        url = `${apiUrl}/${resource}?` + "filter=" + params.filter;
        options.headers = new Headers({
          authorization: localStorage.getItem("token"),
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        options.method = "GET";
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource, params) => {
    let token = response.headers.get("authorization");
    localStorage.setItem("token", token);

    const { json } = response;

    switch (type) {
      case "GET_LIST_COMPETITION":
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (typeof json.totalElements === "undefined") {
          throw new Error("Missing totalElements from json response");
        }
        return {
          data: json.content,
          total: json.totalElements
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case "DOWNLOAD":
        return response;
      case "EXPORT":
      case "EXPORT_LIST_COMPETITION":
        return response;
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);

    if (
      type === "DOWNLOAD" ||
      type === "EXPORT" ||
      type === "EXPORT_LIST_COMPETITION"
    ) {
      return fetch(url, options).then(response =>
        convertHTTPResponseToREST(response, type, resource, params)
      );
    }

    return httpClient(url, options)
      .then(response =>
        convertHTTPResponseToREST(response, type, resource, params)
      )
      .catch(e => {
        let errors = e.body && e.body.errors ? e.body.errors : e.message;
        if (errors === "ERROR.ACCESS_DENIED") {
          invalidateData();
          window.location.href = "/";
          throw new Error();
        } else throw new Error(errors);
      });
  };
};
