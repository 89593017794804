import "babel-polyfill";
import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import "./theme/styles/App.css";
import authProvider from "./providers/authProvider";
import Login from "./Login";
import Layout from "./theme/layouts/Layout";
import Menu from "./theme/partials/Menu.js";
import { Dashboard } from "./pages/dashboard";
import customRoutes from "./routes";
import enMessages from "./i18n/en";
import dataProviderFactory from "./providers/dataProvider";
import addUploadFeature from "./providers/dataProvider/addUploadFeature";
import { ProfileEdit } from "./pages/profile";
import userReducer from "./reducer";
import userSaga from "./sagas/";
import { UserList, UserCreate, UserEdit, UserShow } from "./pages/user";
import { UserRoleList, UserRoleCreate, UserRoleEdit, UserRoleShow } from "./pages/userRoles";
import {CustomerCreate, CustomerShow, CustomerEdit, CustomerList } from "./pages/customers";
import {ProjectCreate, ProjectShow, ProjectEdit, ProjectList } from "./pages/project";
import {ProjectCpList } from "./pages/customerPortal";
import {NoteCreate, NoteEdit, NoteList } from "./pages/note";
import {TechCreate, TechEdit, TechList } from "./pages/tech";
import {TeamCreate, TeamEdit, TeamList } from "./pages/team";
import {ServerCreate, ServerEdit, ServerList } from "./pages/server";
import {ContactCreate, ContactEdit, ContactList } from "./pages/contacts";
import {EmployeeCreate, EmployeeEdit, EmployeeList } from "./pages/emloyees";
import {InvoiceCreate, InvoiceEdit, InvoiceEditIP, InvoiceList } from "./pages/invoice";
import {FlatrateCreate, FlatrateEdit, FlatrateList } from "./pages/flatrate";
import {TasksCreate, TasksEdit, TasksList } from "./pages/tasks";

const i18nProvider = () => {
  return enMessages;
};

class App extends Component {
  state = { uploadCapableDataProvider: null };

  async componentWillMount() {
    const dataProvider = await dataProviderFactory("rest");

    const uploadCapableDataProvider = addUploadFeature(dataProvider);

    this.setState({ uploadCapableDataProvider });
  }

  componentWillUnmount() {
    this.dataProvider();
  }

  render() {
    const { uploadCapableDataProvider } = this.state;

    if (!uploadCapableDataProvider) {
      return (
        <div className="loader-container">
          <div className="loader">Loading...</div>
        </div>
      );
    }

    return (
      <Admin
        title="Optiweb CRM"
        dataProvider={uploadCapableDataProvider}
        authProvider={authProvider}
        customRoutes={customRoutes}
        customReducers={{ identity: userReducer }}
        customSagas={[userSaga]}
        loginPage={Login}
        appLayout={Layout}
        Dashboard={Dashboard}
        menu={Menu}
        locale="en"
        i18nProvider={i18nProvider}
      >
        {permissions => [

          <Resource name="dashboard" edit={Dashboard} />,

          <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
            show={UserShow}
          />,
          <Resource
            name="userRoles"
            list={UserRoleList}
            edit={UserRoleEdit}
            create={UserRoleCreate}
            show={UserRoleShow}
          />,
          <Resource
            name="customers"
            list={CustomerList}
            edit={CustomerEdit}
            create={CustomerCreate}
            show={CustomerShow}
          />,
          <Resource
            name="projects"
            list={ProjectList}
            edit={ProjectEdit}
            create={ProjectCreate}
          />,
          <Resource
            name="notes"
            list={NoteList}
            edit={NoteEdit}
            create={NoteCreate}
          />,
          <Resource
            name="technologies"
            list={TechList}
            edit={TechEdit}
            create={TechCreate}
          />,
          <Resource
            name="teams"
            list={TeamList}
            edit={TeamEdit}
            create={TeamCreate}
          />,
          <Resource
            name="servers"
            list={ServerList}
            edit={ServerEdit}
            create={ServerCreate}
          />,
          <Resource
            name="contacts"
            list={ContactList}
            edit={ContactEdit}
            create={ContactCreate}
          />,
          <Resource
            name="employees"
            list={EmployeeList}
            edit={EmployeeEdit}
            create={EmployeeCreate}
          />,
          <Resource
            name="invoices"
            list={InvoiceList}
            edit={InvoiceEdit}
            editIp={InvoiceEditIP}
            create={InvoiceCreate}
          />,
          <Resource
            name="flatrate"
            list={FlatrateList}
            edit={FlatrateEdit}
            create={FlatrateCreate}
          />,
          <Resource
            name="customerPortal"
            list={ProjectCpList}
          />,
          <Resource
            name="tasks"
            list={TasksList}
            edit={TasksEdit}
            create={TasksCreate}
          />
        ]}
      </Admin>
    );
  }
}

export default App;
