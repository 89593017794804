import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import Button from '@material-ui/core/Button';

class redirectOrder extends Component {

    componentDidMount() {
       
    }

    goto = () =>{
        const { push, record } = this.props;
        push('/positions/'+record.id);
    }

    render() {
        return (
            <>
            <br/><br/><br/>
            <Button onClick={()=>this.goto()} style={{background:"grey",color:"white"}}>Go to invoice positions</Button>
        </>
        )
    };
}

redirectOrder.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
};

export default connect(null, {
    push: pushAction,
})(redirectOrder );