import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  DateField,
  EditButton,
  TextInput,
  Show,
  SimpleShowLayout,
  CardActions,
  ListButton,
  Filter,
  SelectInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import {
    required,
} from "../../validators/validators";

const ContactFilter = props => (
  <Filter {...props}>
    <TextInput
      elStyle={{ width: 380 }}
      label="resources.misc.fields.name"
      source="name"
    />
    <TextInput
      elStyle={{ width: 380 }}
      label="resources.misc.fields.vat"
      source="vat"
    />
  </Filter>
);

export const ContactList = props => (
  <List {...props} filters={<ContactFilter />}  bulkActionButtons={false}>
    <Datagrid>
      <TextField label="resources.misc.fields.name" source="name" />
      <TextField label="resources.misc.fields.email" source="email" />
      <TextField label="resources.misc.fields.phone" source="phone" />
      
      <EditButton label="" /> 
    </Datagrid>
  </List>
);

const ContactTitle = ({ record }) => {
    return (
      <span>{record ? `"${record.name}"` : ""}</span>
    );
};

const cardActionStyle = {
    zIndex: 2,
    display: "inline-block",
    float: "right"
};

const Actions = ({ basePath, data, refresh }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
  </CardActions>
);

export const ContactShow = props => (
  <Show 
  title={<ContactTitle />} 
  actions={<Actions />}
  {...props}>
    <SimpleShowLayout>
      <TextField label="resources.misc.fields.name" source="companyName" />
      <TextField label="resources.misc.fields.address" source="address" />
      <TextField label="resources.misc.fields.city" source="city" />
      <TextField label="resources.misc.fields.zip" source="zip" />
      <TextField label="resources.misc.fields.email" source="email" />
      <TextField label="resources.misc.fields.vat" source="vat" />
    </SimpleShowLayout>
  </Show>
);

export const ContactEdit = ({ permissions, ...props }) => (
  <Edit title={<ContactTitle />} actions={<CardActions />} {...props}>
    <SimpleForm>
      <TextInput label="resources.misc.fields.name" source="name" />
      <TextInput label="resources.misc.fields.email" source="email" />
      <TextInput label="resources.misc.fields.phone" source="phone" />
      <ReferenceInput
            label="Client"
            sort={{ field: "id", order: "ASC" }}
            source="id"
            reference="customers"
          allowEmpty
          >
          <AutocompleteInput optionText="companyName" />
      </ReferenceInput>
      
    </SimpleForm>
  </Edit>
);

export const ContactCreate = ({ permissions, ...props }) => (
  <Create title="Create a Contact" {...props}>
    <SimpleForm redirect="list">
    <TextInput label="resources.misc.fields.name" source="name" />
      <TextInput label="resources.misc.fields.email" source="email" />
      <TextInput label="resources.misc.fields.phone" source="phone" />
      <ReferenceInput
            label="Client"
            sort={{ field: "id", order: "ASC" }}
            source="customer.id"
            reference="customers"
          allowEmpty
        >
          <AutocompleteInput optionText="companyName" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);