import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  Filter,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  CardActions,
  CreateButton,
  SelectInput,
  DateField,
  ReferenceInput
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";


export const ClientIcon = Icon;

const ProjectTitle = ({ record }) => {
  return <span>Project {record ? `"${record.name}"` : ""}</span>;
};


export const NoteList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="note" />
      <TextField source="customer.companyName" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton label="" /> 
    </Datagrid>
  </List>
);


export const NoteCreate = props => (
  <Create title="Create a Note" {...props} >
    <SimpleForm redirect="list">
        <TextInput source="note" />

        <ReferenceInput
            label="Project"
            sort={{ field: "id", order: "ASC" }}
            source="project.id"
            reference="projects"
          allowEmpty
          >
          <AutocompleteInput optionText="name" />
      </ReferenceInput>
        <ReferenceInput
            label="Client"
            sort={{ field: "id", order: "ASC" }}
            source="customer.id"
            reference="customers"
          allowEmpty
          >
          <AutocompleteInput optionText="companyName" />
      </ReferenceInput>

    </SimpleForm>
  </Create>
);

export const NoteEdit = props => (
  <Edit title="Edit Note" actions={<CardActions />} {...props}>
    <SimpleForm>
        <TextInput source="note" />
      <ReferenceInput
            label="Project"
            sort={{ field: "id", order: "ASC" }}
            source="project.id"
            reference="projects"
            perPage={300}
            filterToQuery={searchText => ({ name: searchText })}
          allowEmpty
          >
          <AutocompleteInput optionText="name" />
      </ReferenceInput>
        <ReferenceInput
            label="Client"
            sort={{ field: "id", order: "ASC" }}
            source="customer.id"
            reference="customers"
            perPage={300}
            filterToQuery={searchText => ({ name: searchText })}
          allowEmpty
          >
          <AutocompleteInput optionText="companyName" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);