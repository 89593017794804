import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, SubmissionError, change } from 'redux-form';
import axios from 'axios';
import { Button, WithPermissions } from "react-admin";
import IconContentRemove from "@material-ui/icons/Delete";
import IconAdd from "@material-ui/icons/Add";
import SubIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import IconContentAdd from "@material-ui/icons/Add";
import IconContentSend from "@material-ui/icons/Send";
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import SubdirectoryArrowRight from '@material-ui/icons/SubdirectoryArrowRight';
import Dialog from "@material-ui/core/Dialog";
import SelectInput from '@material-ui/core/Select/SelectInput';
import { isWidthUp } from '@material-ui/core/withWidth';

class InvoicePositions extends Component {
    state = {
        issues: [],
        invoice: [],
        price: 60,
        ow: 0,
        sum: 0,
        combinedText: "Programiranje",
        permissions: "",
        teams: [],
        makeChild: "",
        parent: "",
        reportNeeded: false,
        note: "",
        selectedId: 0,
        loading: 0,
    };

    componentDidMount = () => {
        this.setState({ permissions: localStorage.getItem('permissions') });
        this.getTeams();
        this.getProject();
        this.getInvoicePositions();
    };

    getProject = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoices/' + this.props.match.params.id,
            method: 'get',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            this.setState({ invoice: response.data });
            console.log("INV ", this.state.invoice);
            if (this.state.invoice.name != "") this.setState({ combinedText: this.state.invoice.name })
        }).catch((error) => {
            console.log(error);
        });
    };

    getInvoicePositions = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/invoice/' + this.props.match.params.id,
            method: 'get',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            this.setState({ issues: response.data });
            console.log("already", response.data);
            if (this.state.issues.length > 0) console.log("Already saved!");
            else this.getFlatrates(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    getFlatrates = (onlyFlatrates) => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/invoice/flatrates/' + this.props.match.params.id,
            method: 'get',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            let issuesWL = this.state.issues;
            response.data.forEach(i => {
                //TODO FR TEAMS
                console.log(i);
                console.log("deteted | ", i.deleted);
                console.log("project | ", i.project);
                //console.log("project id | ",i.project.id);
                //console.log("invoice project id | ",this.state.invoice.project.id);
                //console.log("invoice project | ",this.state.invoice.project);

                if (i.deleted != 1 && ((i.project != null && this.state.invoice.project != null && i.project.id === this.state.invoice.project.id) || null === this.state.invoice.project)) issuesWL.push({ summary: i.name, "timeSpent": "1", "price": i.price, ow: "0", "sum": i.price, invoice: this.state.invoice, groupid: 0, flatrate: i.id, ident: 0, parent: -1, grouped: 0 })
            })
            this.setState({ issues: issuesWL });
            if (!onlyFlatrates) this.getProjects(0);
        }).catch((error) => {
            console.log(error);
        });
    };

    getTeams = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/teams/list',
            method: 'get',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            this.setState({ teams: response.data });
        }).catch((error) => {
            console.log(error);
        });
    };

    getWorklogs = (ix, delay, key) => {
        setTimeout(() => {
            const authToken = localStorage.getItem('token');
            console.log("axios key", key);
            axios({
                url: '/api/jira/worklog/' + key,
                method: 'get',
                headers: {
                    Authorization: authToken,
                },
            }).then(response => {

                let worklogs = response.data.worklogs;
                let ts = 0;
                let sum = 0;
                worklogs.forEach(w => {

                    let dateTo = this.state.invoice.workedTo.split("-");
                    let day = dateTo[2].split("T");
                    let dateToStr = dateTo[0] + "-" + dateTo[1] + "-" + day[0] + "T23:59:59.000+0000";
                    console.log(key, "-", w.started, "-", this.state.invoice.workedFrom, "-", dateToStr);
                    if (w.started >= this.state.invoice.workedFrom && dateToStr >= w.started) {
                        ts = ts + w.timeSpentSeconds;
                    }

                })

                ts = ts / 60 / 60;
                ts = parseFloat(ts).toFixed(2);
                let issues = this.state.issues;
                let issuesWL = [];

                issues.forEach((i, index) => {
                    if (ix === index) {
                        issuesWL.push({ summary: i.summary, "timeSpent": ts, "price": i.price, ow: i.ow, "sum": parseFloat(i.price * ts).toFixed(2), invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
                    } else {
                        issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
                    }
                    console.log("grouped x", i.grouped);
                })
                this.setState({ issues: issuesWL });
            }).catch((error) => {
                console.log(error);
            });
        }, delay);

    };


    checkQty() {

        let issuesTmp = this.state.issues;
        let delay = 0;
        issuesTmp.forEach((i, index) => {
            //if (i.assignee) {
            this.getWorklogs(index, delay, i.key);
            delay = delay + 300;
            //}
        });
        setTimeout(() => {
            let issuesDeleted = this.state.issues;
            let issuesWP = [];
            issuesDeleted.forEach((i, index) => {
                if (i.timeSpent > 0) issuesWP.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            });
            //preveri zakaj so nule
            this.setState({ issues: issuesWP });
            //console.log("konec loading");
            this.setState({ loading: 0 });
        }, delay + 100);
        console.log("PO", this.state.issues);
    }

    getUserData = (name, issue, delay) => {
        setTimeout(() => {
            const authToken = localStorage.getItem('token');
            axios({
                url: '/api/jira/userData',
                method: 'post',
                data: { name: name },
                headers: {
                    Authorization: authToken,
                },
            }).then(response => {
                let issues = this.state.issues;
                let issuesWL = [];
                issues.forEach((i, index) => {
                    if (i.summary === issue) {
                        issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": response.data.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: response.data.team.id, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.group })
                    } else {
                        issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
                    }
                })
                // this.setState({ issues: issuesWL });
            }).catch((error) => {
                console.log(error);
            });
        }, delay);
    };

    checkUserData() {
        let issuesTmp = this.state.issues;
        let delay = 0;
        issuesTmp.forEach((i, index) => {
            if (i.assignee) {
                this.getUserData(i.assignee.displayName, i.summary, delay);
                delay = delay + 100;
            }
        });
    }

    getProjects = (offset) => {
        this.setState({ loading: 1 });
        axios({
            url: '/api/jiraRest/projects/' + this.props.match.params.id + "/" + offset,
            method: 'get',
        }).then(response => {
            let issues = response.data;
            let issuesWL = this.state.issues;
            if (issues == undefined) {
                this.setState({ loading: 0 });
            }
            console.log("iSaved ssues: ", response.data);
            response.data.forEach(i => {
                console.log("tasks");
                console.log(i.fields.summary);
                console.log(i.fields.worklog.total);
                console.log("Price: ", this.state.invoice);

                let hourlyRate = 60;
                if (this.state.invoice.project && this.state.invoice.project.hourlyRate != null) {
                    hourlyRate = parseFloat(this.state.invoice.project.hourlyRate).toFixed(2);
                }
                issuesWL.push({ ...i.fields, key: i.key, "timeSpent": 0, "sum": 0, price: hourlyRate, ow: 0, invoice: this.state.invoice, groupid: 0, flatrate: 0, ident: 44, parent: -1, grouped: 0 })


            })
            this.setState({ issues: issuesWL })
            //this.checkUserData();
            console.log("checkQty");
            this.checkQty();
            //TODO
        }).catch((error) => {
            console.log(error);
        });
    };

    onChangeT = (e, iNew, ix) => {
        let issues = this.state.issues;
        let issuesWL = [];
        issues.forEach((i, index) => {
            if (iNew.summary === i.summary && ix === index) {
                issuesWL.push({ summary: e.target.value, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            } else {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            }
        })
        this.setState({ issues: issuesWL })
    }

    onChangeQ = (e, iNew, ix) => {
        let issues = this.state.issues;
        let issuesWL = [];
        issues.forEach((i, index) => {
            if (iNew.summary === i.summary && ix === index) {
                issuesWL.push({ summary: i.summary, "timeSpent": e.target.value, "price": i.price, ow: i.ow, "sum": parseFloat(i.price * e.target.value).toFixed(2), invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            } else {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            }
        })
        this.setState({ issues: issuesWL })
    }

    onChangeIdent = (e, iNew, ix) => {
        let issues = this.state.issues;
        let issuesWL = [];
        issues.forEach((i, index) => {
            if (iNew.summary === i.summary && ix === index) {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: e.target.value, parent: i.parent, grouped: i.grouped })
            } else {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            }
        })
        this.setState({ issues: issuesWL })
    }

    onChange = (e, iNew, ix) => {
        let price = 0.00;
        if (e.target.value > 0) price = e.target.value;
        this.setState({ price: e.target.value });
        let issues = this.state.issues;
        let issuesWL = [];
        price = parseFloat(price);
        issues.forEach((i, index) => {
            if (iNew.summary === i.summary && ix === index) {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": price, ow: i.ow, "sum": parseFloat(price * i.timeSpent).toFixed(2), invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            } else {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            }
        })
        this.setState({ issues: issuesWL })
    }


    onChangeS = (e, iNew) => {
        let price = 0;
        if (e.target.value > 0) price = e.target.value;
        this.setState({ price: e.target.value });
        let issues = this.state.issues;
        let issuesWL = [];
        issues.forEach(i => {
            if (iNew.summary === i.summary) {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": price, ow: i.ow, "sum": (price * i.timeSpent) + (price * i.ow), invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            } else {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            }
        })
        this.setState({ issues: issuesWL })
    }

    onChangeNote = (value) => {
        let i = this.state.invoice;
        i.note = value;
        this.setState({ invoice: i });
    }

    makeChild = () => {
        let price = 0;
        let issues = this.state.issues;
        let issuesWL = [];
        issues.forEach((i, index) => {
            if (this.state.makeChild == index) {
                //console.log("noter: ", i.summary, " parent: ", i.parent, " selscted: ", this.state.selectedId);
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: price, "sum": i.sum, invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: this.state.selectedId, grouped: i.grouped })
            } else {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: price, "sum": i.sum, invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped })
            }
        })
        this.setState({ issues: issuesWL })
        console.log("sub", this.state.issues);
        //this.handleCancel();
    }

    onChangeCombo = (e) => {
        this.setState({ combinedText: e.target.value })
    }

    sumSum = () => {
        let sum = parseFloat(0.00);
        this.state.issues.map((i, index) => (
            sum = (i.grouped !== 1 || i.flatrate !== 0) ? sum + parseFloat(i.sum) : sum
            //sum = sum + parseFloat(i.sum)
        ));
        return sum.toFixed(2);
    }

    // sumSum = () => {
    //     let sum = parseFloat(0.00);
    //     this.state.issues.map((i, index) => (
    //         sum = sum + parseFloat(i.sum)
    //     ));
    //     return sum.toFixed(2);
    // }

    sumQty = () => {
        let sum = parseFloat(0.00);
        this.state.issues.map((i, index) => (
            sum = (i.flatrate === 0 && i.grouped !== 1) ? sum + parseFloat(i.timeSpent) : sum
        ));
        return sum.toFixed(2);
    }

    sumQtyParent = (summary, ix) => {
        let sum = 0;
        this.state.issues.map((i, index) => (
            sum = (i.flatrate === 0 && i.parent === ix) ? sum + parseFloat(i.timeSpent) : sum
        ));
        return sum.toFixed(2);
    }

    // sumPriceParent = (summary, ix) => {
    //     let price = 60;
    //     this.state.issues.map((i, index) => (
    //         price = (i.flatrate === 0 && i.parent === ix && this.getParentId(i.parent, ix) === ix) ? i.price : price
    //     ));
    //     return price;
    // }

    sumPriceParent = (summary, ix) => {
        let price = 0;
        let defaultPrice = 60;
        let averagePrice = 0;
        let qty = 0;

        this.state.issues.forEach((i, index) => {
            //console.log("ix", ix, "| parent", i.parent, "| parent2", this.getParentId(i.parent, ix));
            //console.log("summary - average", i.summary, "price", i.price);
            if (i.flatrate === 0 && i.parent === ix) {
                //console.log("ix", ix, "| parent", i.parent, "| parent2", this.getParentId(i.parent, ix));
                // console.log("summary - average", i.summary, "price", i.price, "i.sum", i.sum, "i.timeSpent", i.timeSpent);

                if (i.price > 0 && i.timeSpent > 0) {
                    averagePrice += parseFloat(i.sum);
                    qty = qty + parseFloat(i.timeSpent);
                }
            }
        });

        if (qty > 0) {
            //round to 2 decimals
            averagePrice = (averagePrice / qty).toFixed(3);
            price = averagePrice;
        } else {
            price = defaultPrice;
        }

        return price;
    }

    getParentId = (summary, ix) => {
        let ixr = 0;
        this.state.issues.map((i, index) => (
            (i.parent === summary && index === ix) ? (
                ixr = index
            ) : null
        ));

        return ixr;
    }

    sumSumParent = (summary, ix) => {
        let sum = parseFloat(0.00);
        this.state.issues.map((i, index) => (
            //console.log("sumSumParent ||| summary", i.summary, "| parent", i.parent, "| ix", ix, "| ix_this",this.getParentId(i.parent, ix),"| sum", i.sum),
            sum = (i.parent === ix) ? sum + parseFloat(i.sum) : sum
            //console.log("sum", sum)
        ));

        //console.log("sumSumParent ||| sum", sum)
        return sum.toFixed(2);
    }

    // sumSumParent = (summary, ix) => {
    //     let sum = parseFloat(0.00);
    //     this.state.issues.map((i, index) => (
    //         sum = (i.parent === ix) ? sum + parseFloat(i.sum) : sum
    //     ));

    //     return sum.toFixed(2);
    // }

    removeLine = (k, ix) => {
        let issues = [];
        let issueOld = this.state.issues;
        issueOld.forEach((i, index) => {
            if (index !== ix && k !== i.summary) {
                let newParent = (i.parent > -1) ? i.parent - 1 : -1;
                if (ix > i.parent) newParent = i.parent;
                issues.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: 0, "sum": i.sum, invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: newParent, grouped: i.grouped })
            }
        })
        this.setState({ issues })
    }

    removeSubLine = (k, ix, hi) => {
        console.log("delete subtask ", ix)
        let issues = [];
        let issueOld = this.state.issues;
        issueOld.forEach((i, index) => {
            console.log(" subtask ", index);

            if (index !== ix) {
                let newParent = (i.parent > -1) ? i.parent - 1 : -1;
                if (ix > i.parent) newParent = i.parent;
                issues.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: 0, "sum": i.sum, invoice: this.state.invoice, groupid: i.group, flatrate: i.flatrate, ident: i.ident, parent: newParent, grouped: i.grouped })

            } else {

                console.log("briši", i.summary);
            }

        })
        this.setState({ issues })
        console.log(this.state.issues);
    }

    addLine = () => {
        let issues = this.state.issues;
        let price = (this.state.invoice.project != null && this.state.invoice.project.hourlyRate != null) ? this.state.invoice.project.hourlyRate : 0;
        issues.push({ summary: "Nov task " + (this.state.issues.length + 1), "timeSpent": "0", "price": price, ow: "0", "sum": "0", invoice: this.state.invoice, groupid: 0, flatrate: 0, ident: 44, parent: -1, grouped: 0 })
        this.setState({ issues })
    }

    addFlateRateLine = () => {
        let issues = this.state.issues;
        issues.push({ summary: "Nov task " + (this.state.issues.length + 1), "timeSpent": "1", "price": "0", ow: "0", "sum": "0", invoice: this.state.invoice, groupid: 0, flatrate: 1, ident: 0, parent: -1, grouped: 0 })
        this.setState({ issues })
    }

    addGroupedLine = () => {
        let issues = this.state.issues;
        issues.push({ summary: "Nova grupa " + (this.state.issues.length + 1), "timeSpent": "1", "price": "0", ow: "0", "sum": "0", invoice: this.state.invoice, groupid: 0, flatrate: 0, ident: 0, parent: -1, grouped: 1 })
        this.setState({ issues })
    }

    saveInvoice = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoices/' + this.state.invoice.id,
            method: 'PUT',
            data: this.state.invoice,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            // this.saveInv();
        }).catch((error) => {
            console.log(error);
        });
    }

    onlySave = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/save',
            method: 'post',
            data: this.state.issues,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            // this.saveInv();
            this.saveInvoice();
        }).catch((error) => {
            console.log(error);
        });
    }

    saveInv = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/saveInv/' + this.state.invoice.id,
            method: 'post',
            data: this.state.combinedText,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    delete = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoices/' + this.state.invoice.id + '/delete',
            method: 'post',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            this.props.push("/invoices");
        }).catch((error) => {
            console.log(error);
        });
    }

    save = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/saveMarkReady',
            method: 'post',
            data: this.state.issues,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            //this.saveInv();
            this.saveInvoice();
            this.props.push("/invoices");
        }).catch((error) => {
            console.log(error);
        });
    }

    confirm = () => {
        const authToken = localStorage.getItem('token');
        console.log(this.state.issues);
        axios({
            url: '/api/invoicePositions/confirm',
            method: 'post',
            data: this.state.issues,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            //this.saveInv();
        }).catch((error) => {
            console.log(error);
        });
    }

    confirmVasco = () => {

        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/vascoConfirm',
            method: 'post',
            data: this.state.issues,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            // this.saveInv();
        }).catch((error) => {
            console.log(error);
        });
    }

    vascoAuth = () => {
        let issues = this.state.issues;
        let issuesWL = [];
        issues.forEach((i, index) => {
            if (i.grouped === 1) {
                issuesWL.push({ summary: i.summary, "timeSpent": this.sumQtyParent(i.summary, index), "price": this.sumPriceParent(i.summary, index), ow: i.ow, "sum": this.sumSumParent(i.summary, index), invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped });
            } else {
                issuesWL.push({ summary: i.summary, "timeSpent": i.timeSpent, "price": i.price, ow: i.ow, "sum": i.sum, invoice: this.state.invoice, groupid: i.groupid, flatrate: i.flatrate, ident: i.ident, parent: i.parent, grouped: i.grouped });
            }
        })

        this.setState({ issues: issuesWL });
        console.log("Sending to Vasco: ", issuesWL);

        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/vascoAuth',
            method: 'post',
            data: issuesWL,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            //console.log(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    vasco = (key) => {
        const authToken = localStorage.getItem('token');
        axios({
            url: '/api/invoicePositions/vasco',
            method: 'post',
            data: key,
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            // console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    getDate = (date) => {
        var localDate = new Date(date);
        return localDate.getDate() + "." + (localDate.getMonth() + 1) + "." + localDate.getFullYear();
    }

    getClient = () => {
        var client = (this.state.invoice.project && this.state.invoice.project.customer) ? this.state.invoice.project.customer.companyName : (this.state.invoice.customer) ? this.state.invoice.customer.companyName : "";
        return client;
    }

    handleOpen = (parent, index) => {
        this.setState({ open: true });
        this.setState({ makeChild: "" });
        this.setState({ selectedId: index });
        this.setState({ parent: parent });
    };

    handleCancel = () => {
        this.setState({ open: false });
        this.setState({ makeChild: "" });
    };

    handleWithHead = () => {
        this.startDownload(true);
        this.setState({ open: false });
    };

    handleWithoutHead = () => {
        this.startDownload(false);
        this.setState({ open: false });
    };

    checkForChilds = () => {
        return true;
    }


    onChangeReport = () => {
        console.log(this.state.invoice.reportNeeded);
        let i = this.state.invoice;
        i.reportNeeded = (this.state.invoice.reportNeeded == null || !this.state.invoice.reportNeeded) ? true : false;
        this.setState({ invoice: i });
        console.log(this.state.invoice.reportNeeded);
    }



    render() {

        return (
            <div>
                <div style={{ background: "white", borderRadius: "3px 3px 3px 3px", padding: "15px" }}>
                    <h1 style={{ color: "grey" }}>Invoice  <span style={{ fontSize: "15px", color: "#24a8ff", cursor: "pointer" }} onClick={() => this.props.push("/invoices")}>[back to list]</span></h1>

                    <div>
                        <div style={{ float: "left", }}>
                            <p><b>Date range:</b>{this.getDate(this.state.invoice.workedFrom) + " - " + this.getDate(this.state.invoice.workedTo)}</p>
                            <p><b>Client:</b>  {this.getClient()}</p>
                            <p><b>Project:</b> {(this.state.invoice.project != null) ? this.state.invoice.project.name : ""}</p>
                            <p><b>Order number:</b> {this.state.invoice.orderNumber}</p>
                        </div>
                        <div style={{ float: "right", paddingRight: "130px" }}>
                            <p><b>Created by:</b> {this.state.invoice.user}</p>
                            <p><b>Created at: </b>{this.getDate(this.state.invoice.createdAt)}</p>
                            <p><b>Updated at:</b> {this.getDate(this.state.invoice.updatedAt)}</p>
                            <p><b>Status:</b>  {this.state.invoice.status}</p>
                        </div>
                    </div>

                    <hr style={{ "border": "solid 1px rgb(235 235 235)", clear: "both", margintTop: "20px" }} />
                    <br />
                    <br />
                    <br />

                    {(this.state.loading == 1) ? (
                        <img src="/images/loading.gif" style={{ width: "100px", height: "100px", marginLeft: "40%" }} />
                    ) : (
                        <></>
                    )}

                    <div style={
                        (this.state.loading == 1) ?
                            { fontSize: "16px", color: "black", height: "100%", width: "100%", marginBottom: "15px", display: "none" } : { fontSize: "16px", color: "black", height: "100%", width: "100%", marginBottom: "15px" }

                    }>
                        <table style={{ fontSize: "16px", color: "black", height: "100%", width: "100%", marginBottom: "15px" }} >
                            <thead>
                                <tr>
                                    <td width={"30%"}><b>Name</b></td>
                                    <td width={"10%"}><b>Ident</b></td>
                                    <td width={"10%"}><b>Group</b></td>
                                    <td width={"10%"}><b>Qty</b></td>
                                    <td width={"8%"}><b>Price</b></td>
                                    <td width={"8%"}><b>Sum</b></td>
                                    <td width={"14%"}></td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.issues.map((i, index) => (
                                    (i.parent === -1) ? (
                                        <>
                                            <tr style={(i.flatrate > 0) ? { background: "#f3f399" } : { background: "#fff" }}>
                                                <td><input type="text" value={i.summary} onChange={(e) => this.onChangeT(e, i, index)} style={{ fontSize: "16px", width: "80%", border: "1px solid #e4e4e4", padding: "5px", borderRadius: "5px" }} /></td>
                                                <td>
                                                    <select style={{ padding: "7px" }}
                                                        onChange={(e) => this.onChangeIdent(e, i, index)}
                                                    >
                                                        <option value={0} selected={(i.ident === 0) ? "selected" : ""}>Choose...</option>
                                                        <option value={68} selected={(i.ident === 68) ? "selected" : ""}>HR akademija</option>
                                                        <option value={49} selected={(i.ident === 49) ? "selected" : ""}>Linkedin delavnica</option>
                                                        <option value={8} selected={(i.ident === 8) ? "selected" : ""}>Osnovna sredstva</option>
                                                        <option value={46} selected={(i.ident === 46) ? "selected" : ""}>Pavšal</option>
                                                        <option value={58} selected={(i.ident === 58) ? "selected" : ""}>Poslovno svetovanje</option>
                                                        <option value={5} selected={(i.ident === 5) ? "selected" : ""}>Refferal program</option>
                                                        <option value={26} selected={(i.ident === 26) ? "selected" : ""}>Marketing</option>
                                                        <option value={4} selected={(i.ident === 4) ? "selected" : ""}>Ostalo</option>
                                                        <option value={13} selected={(i.ident === 13) ? "selected" : ""}>Zalaganje sredstev</option>
                                                        <option value={10} selected={(i.ident === 10) ? "selected" : ""}>Analiza in svetovanje</option>
                                                        <option value={33} selected={(i.ident === 33) ? "selected" : ""}>Gostovanje</option>
                                                        <option value={34} selected={(i.ident === 34) ? "selected" : ""}>Nakup ali podaljšanje domene</option>
                                                        <option value={44} selected={(i.ident === 44) ? "selected" : ""}>Razvoj</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select style={{ padding: "7px" }}
                                                        onChange={() => alert(1)}
                                                    >
                                                        <option value={0} selected={(i.groupid === 0) ? "selected" : ""}>Choose...</option>
                                                        {this.state.teams.map((t, index) => (
                                                            <option value={t.id} selected={(i.group === t.id) ? "selected" : ""}>{t.name}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    {(i.grouped === 1) ? (
                                                        <>{this.sumQtyParent(i.summary, index)}</>
                                                    ) : (
                                                        <><input type="text" value={i.timeSpent} onChange={(e) => this.onChangeQ(e, i, index)} style={{ fontSize: "16px", width: "40px", border: "1px solid #e4e4e4", padding: "5px", borderRadius: "5px" }} /></>
                                                    )}
                                                </td>
                                                <td>
                                                    {(i.grouped === 1) ? (
                                                        <></>
                                                    ) : (
                                                        <><input type="number" min={0} step={0.01} presicion={2} value={i.price} onChange={(e) => this.onChange(e, i, index)} style={{ fontSize: "16px", width: "75px", border: "1px solid #e4e4e4", padding: "5px", borderRadius: "5px"}} /> € </>
                                                    )}
                                                </td>
                                                <td>
                                                    {(i.grouped === 1) ? (
                                                        <>{this.sumSumParent(i.summary, index)} €</>
                                                    ) : (
                                                        <>{i.sum} € </>
                                                    )}</td>
                                                <td> {(this.sumQtyParent(i.summary, index) == 0 && (this.state.permissions.includes('IS_ADMIN') || this.state.permissions.includes('IS_PM'))) ? (
                                                    <Button onClick={() => this.removeLine(i.summary, index)} label="" style={{ "color": "#db0000" }}>
                                                        <IconContentRemove style={{ "color": "#e86666" }} />
                                                    </Button>
                                                ) : (<></>)}

                                                    {(i.grouped === 1) ? (
                                                        <Button onClick={() => this.handleOpen(i.summary, index)} label="" style={{ "color": "#db0000" }}>
                                                            <SubIcon style={{ "color": "#24a8ff" }} />
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                            {(i.grouped === 1) ? (
                                                this.state.issues.map((k, subIndex) => (
                                                    (index === k.parent) ? (
                                                        <tr style={(k.flatrate > 0) ? { background: "#f3f399" } : { background: "#fff" }}>
                                                            <td style={{ paddingLeft: "5px" }}><SubdirectoryArrowRight style={{ "color": "#24a8ff" }} /><input type="text" value={k.summary} onChange={(e) => this.onChangeT(e, k, subIndex)} style={{ fontSize: "16px", width: "80%", border: "1px solid #e4e4e4", padding: "5px", borderRadius: "5px" }} /></td>
                                                            <td>
                                                            </td>
                                                            <td>
                                                                <select style={{ padding: "7px" }}
                                                                    onChange={() => alert(1)}
                                                                >
                                                                    <option value={0} selected={(i.group === 0) ? "selected" : ""}>Choose...</option>
                                                                    {this.state.teams.map((t, index) => (
                                                                        <option value={t.id} selected={(i.group === t.id) ? "selected" : ""}>{t.name}</option>
                                                                    ))}
                                                                </select></td>
                                                            <td><input type="text" value={k.timeSpent} onChange={(e) => this.onChangeQ(e, k, subIndex)} style={{ fontSize: "16px", width: "40px", border: "1px solid #e4e4e4", padding: "5px", borderRadius: "5px" }} /></td>
                                                            <td><input type="number" min={0} step={0.01} presicion={2} value={k.price} onChange={(e) => this.onChange(e, k, subIndex)} style={{ fontSize: "16px", width: "75px", border: "1px solid #e4e4e4", padding: "5px", borderRadius: "5px" }} /> € </td>
                                                            <td>{k.sum}</td>
                                                            <td>
                                                                {((this.state.permissions.includes('IS_ADMIN') || this.state.permissions.includes('IS_PM')) ? (
                                                                    <Button onClick={() => this.removeSubLine(k.summary, subIndex, index)} label="" style={{ "color": "#db0000" }}>
                                                                        <IconContentRemove style={{ "color": "#e86666" }} />
                                                                    </Button>
                                                                ) : (<></>))}
                                                            </td>
                                                        </tr>
                                                    ) : (<></>)
                                                ))
                                            ) : (<></>)}
                                        </>
                                    ) : (<></>)
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        {((this.state.permissions.includes('IS_ADMIN') || this.state.permissions.includes('IS_PM')) ? (
                                            <>
                                                <Button label="Add" onClick={() => this.addLine()} style={{ "color": "#2179b3", "margin": "0px" }}>
                                                    <IconContentAdd />
                                                </Button> <br />
                                                <Button label="Flatrate" onClick={() => this.getFlatrates(true)} style={{ "color": "#2179b3", "margin": "0px" }}>
                                                    <IconContentAdd />
                                                </Button><br />
                                                <Button label="Group" onClick={() => this.addGroupedLine()} style={{ "color": "#2179b3", "margin": "0px" }}>
                                                    <IconContentAdd />
                                                </Button>
                                            </>
                                        ) : (<></>))}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="3"><hr style={{ "border": "solid 1px rgb(210 210 210)" }} /></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><strong>Total: {this.sumQty()} {this.state.invoice.unit}</strong></td>
                                    <td></td>
                                    <td><strong>{this.sumSum()} €</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div style={{ padding: "10px", display: "block" }}>
                            <span>Note: </span><br /><br />
                            <textarea rows="10" cols="100" style={{ padding: "10px", fontSize: "16px" }} value={this.state.invoice.note} onChange={(e) => this.onChangeNote(e.target.value)} /><br /><br />
                            <span>Report needed: </span>
                            <input type="checkbox" checked={this.state.invoice.reportNeeded} onClick={(e) => this.onChangeReport()} />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <hr style={{ "border": "solid 1px rgb(235 235 235)" }} />

                    {((this.state.permissions.includes('IS_ADMIN') || this.state.invoice.status !== "Vasco") ? (
                        <>
                            {((this.state.permissions.includes('IS_ADMIN') && this.state.invoice.status !== "Vasco") ? (
                                <>
                                    <Button label="Vasco" onClick={() => this.vascoAuth()} style={{ "color": "green", "float": "right" }}>
                                        <IconContentSend style={{ "color": "green" }} />
                                    </Button>
                                </>
                            ) : (<span></span>))}

                            {(this.state.permissions.includes('IS_ADMIN') ? (
                                <Button label="Confirm" onClick={() => this.confirm()} style={{ "color": "green", "float": "right" }}>
                                    <IconContentSend style={{ "color": "green" }} />
                                </Button>
                            ) : (<span></span>))}

                            {((this.state.permissions.includes('IS_ADMIN') || this.state.permissions.includes('IS_PM')) ? (
                                <>
                                    <Button label="Delete" onClick={() => this.delete()} style={{ "color": "#e86666" }}>
                                        <IconContentRemove style={{ "color": "#e86666" }} />
                                    </Button>
                                    <Button label="Save and mark as ready" onClick={() => this.save()} style={{ "color": "green", "float": "right" }}>
                                        <IconContentSend style={{ "color": "green" }} />
                                    </Button>
                                    <Button label="Save" onClick={() => this.onlySave()} style={{ "color": "#2179b3", "float": "right" }}>
                                        <IconContentSend style={{ "color": "#24a8ff" }} />
                                    </Button>
                                </>

                            ) : (<span></span>))}
                        </>
                    ) : (<span></span>))}
                </div>


                <Dialog
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                    <DialogTitle>
                        Add positions
                    </DialogTitle>
                    <DialogContent>
                        <select style={{ padding: "7px" }} onChange={(e) => this.setState({ makeChild: e.target.value })}>
                            {this.state.issues.map((i, index) => (
                                <option value={index} style={{ "display": (index === this.state.selectedId) ? "none" : "block" }}>{i.summary}</option>
                            ))}
                        </select>
                        <br />
                        <Button label="Add" onClick={() => this.makeChild()} style={{ "color": "green" }}>
                            <IconContentRemove style={{ "color": "green" }} />
                        </Button>
                        <Button label="Close" onClick={() => this.handleCancel()} style={{ "color": "red" }}>
                            <IconContentRemove style={{ "color": "red" }} />
                        </Button>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

InvoicePositions.propTypes = {
    ...propTypes,
    push: PropTypes.func,
    theme: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

InvoicePositions.defaultProps = {
    theme: {},
};

export default connect(null, {
    push: pushAction,
})(InvoicePositions);