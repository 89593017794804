// React imports
import React, { Component, Fragment } from "react";

// React Admin imports
import { Responsive, Title } from "react-admin";

// Material imports
import Grid from "@material-ui/core/Grid";

// Other imports
import Welcome from "./Welcome";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginBottom: "30px" },

  root: {
    flexGrow: 1
  },
  paper: {
    padding: "15px",
    textAlign: "center"
  }
};

class Dashboard extends Component {
  componentDidMount() {
    /* const aMonthAgo = new Date();
        aMonthAgo.setDate(aMonthAgo.getDate() - 30);

        dataProviderFactory("rest").then(
            dataProvider => {

            }
        );*/
  }

  render() {
    return (
      <div style={{background:"#fff", padding:"10px", width:"500px", borderRadius:"4px"}}>
        <Fragment >
          <img src="images/banner.jpeg" width="500px"/>
          <p style={{color: "grey", fontFamily: "sans-serif !important", fontSize: "12px"}}>The world wide web is our playground; we dare to dream innovatively and build differently. Because we were born in a digital era we breathe, hear, feel … digitally. We are the sieve that picks through the countless trends in the web space and selects the best and most important solutions for each individual company.</p>
        </Fragment> 
      </div>
    );
  }
}

export default Dashboard;
