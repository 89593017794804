import React from 'react'
import { Route } from 'react-router-dom'
import Configuration from './pages/configuration/Configuration'
import Jira from './pages/emloyees/Jira'
import Report from './pages/Reports/report'
import Positions from './pages/invoice/InvoicePositions'
import Board from './pages/customerPortal/board'
import Issue from './pages/customerPortal/issue'
import Dashboard from './pages/dashboard/Dashboard'

export default [
  <Route exact key="configuration" path="/configuration" component={Configuration} />,
  <Route exact key="jira" path="/jira" component={Jira} />,
  <Route exact key="reports" path="/reports" component={Report} />,
  <Route exact key="positions" path="/positions/:id" component={Positions} />,
  <Route exact key="board" path="/board/:id" component={Board} />,
  <Route exact key="board" path="/issue/:id" component={Issue} />,
  <Route exact key="dashboard" path="/dashboard/" component={Dashboard} />,
]
