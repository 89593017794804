import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FlatButton from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import axios from 'axios';
import { translate } from 'react-admin';
import download from 'downloadjs';
import { refreshView as refreshViewAction } from 'react-admin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import FileDownload from "@material-ui/icons/FileDownload";

class CoommentButton extends Component {
    state = {
        open: false,
    };


    startDownload = () => {
        const { record, recordId, fileName, controller, refreshView } = this.props;
        console.log(record);

        const authToken = localStorage.getItem('token');
        let url = '/api/invoices/pdfreporteur/'+record.id;

        axios({
            url: url,
            method: 'get',
            responseType: 'blob',
            headers: {
                Authorization: authToken,
                Accept: 'application/json',
            },
        }).then(response => {
            let filename = 'report.pdf';
            download(response.data, filename);
            refreshView();
        }).catch((error) => {
            console.log(error);
        });
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleCancel = () => {
        this.setState({open: false});
    };

    render() {
        const {translate,label,icon,record,controller} = this.props;
       
        return (
            <div>
                 <Dialog
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                <DialogTitle>
                   Comments
                </DialogTitle>
                <DialogContent>
                    
                    <span style={{"fontSize": "12px",  "padding": "20px"}}>13. 3. 2023 15:03 - Jaka D.</span>
                    <div style={{"borderBottom": "1px solid grey", "margin": "10px", "padding": "10px"}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                    </div>
                    
                    <span style={{"fontSize": "12px",  "padding": "20px"}}>13. 3. 2023 15:03 - Uros S.</span>
                    <div style={{"borderBottom": "1px solid grey", "margin": "10px", "padding": "10px"}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                    </div>

                    <span style={{"fontSize": "12px",  "padding": "20px"}}>13. 3. 2023 15:03 - Anže S.</span>
                    <div style={{"borderBottom": "1px solid grey", "margin": "10px", "padding": "10px"}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                    </div>
                    <br/>
                    <div style={{"margin": "10px", "padding": "10px"}}>
                    <textarea  style={{"width": "90%",  "height": "70px"}}>

                    </textarea>
                    <br/>
                    <Button label="Close" onClick={() => this.handleCancel()} style={{ "color": "green" }}>
                        Add
                    </Button>
                    <Button label="Close" onClick={() => this.handleCancel()} style={{ "color": "red" }}>
                        Close
                    </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
                </Dialog>
                <Button variant='contained' label={translate(label)} onClick={this.handleOpen} style={{float:'left', background:"none", boxShadow:"0 0 0 white"}}> Comments </Button>
            </div>
        );
    };
}

CoommentButton.propTypes = {
    record: PropTypes.object,
    recordId: PropTypes.number,
    fileName: PropTypes.string,
    label: PropTypes.string,
    controller: PropTypes.string,
};

const enhance = compose(
    translate,
    connect(null, {
        refreshView: refreshViewAction,
    }),
);

export default enhance(CoommentButton);