import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FlatButton from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import axios from 'axios';
import { translate } from 'react-admin';
import download from 'downloadjs';
import { refreshView as refreshViewAction } from 'react-admin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { push as pushAction } from 'react-router-redux';
import IconList from "@material-ui/icons/List";

class EditPositionsButton extends Component {
    state = {
        open: false,
    };


    startDownload = () => {
        
        const { push, record } = this.props;
        
        push('/positions/'+record.id);
    };

    render() {
        const {translate,label,icon,record,controller} = this.props;
       
        return (
            <div>
                 <Button variant='contained' label={""} onClick={this.startDownload} style={{float:'left', background:"none", boxShadow:"0 0 0 white"}}>< IconList /> </Button>
            </div>
        );
    };
}

EditPositionsButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
};

export default connect(null, {
    push: pushAction,
})(EditPositionsButton );