const PWREG = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,128}$/);
const PWREGEDIT = new RegExp(/(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,128}$)|(^$)/);

export const password = (
  message = "PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC"
) => (value, allValues, props) =>
  PWREG.test(value) ? undefined : props.translate(message);

export const passwordEdit = (
  message = "PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC"
) => (value, allValues, props) =>
  PWREGEDIT.test(value) || value === "" || typeof value == "undefined"
    ? undefined
    : props.translate(message);

export const passwordEquals = (message = "PW_MUST_MATCH") => (
  value,
  allValues,
  props
) => (value === allValues.password ? undefined : props.translate(message));

export const required = (message = "FIELD_IS_REQUIRED") => (
  value,
  allValues,
  props
) => (value ? undefined : props.translate(message));
