// React imports
import React, { PureComponent, Children, cloneElement } from "react";
import { connect } from "react-redux";

// Other imports
import compose from "recompose/compose";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material Ui imports
import Drawer from "@material-ui/core/Drawer";
import { withStyles, createStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

// React Admin imports
import { Responsive, setSidebarVisibility, toggleSidebar } from "react-admin";

// Icon imports
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const DRAWER_WIDTH = 180;
export const CLOSED_DRAWER_WIDTH = 55;

const styles = theme =>
  createStyles({
    drawerPaper: {
      position: "relative",
      height: "100%",
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: "#ffffff",
      marginTop: 0,
      borderRight: "1px solid #eeeeee",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
        height: "100vh",
        position: "inherit",
        backgroundColor: theme.palette.background.default
      },
      [theme.breakpoints.up("md")]: {
        marginTop: 0
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    toolbarOpen: {
      backgroundColor: "#fff"
    },
    toolbarClosed: {
      backgroundColor: "#fff"
    }
  });

// We shouldn't need PureComponent here as it's connected
// but for some reason it keeps rendering even though mapStateToProps returns the same object
class Sidebar extends PureComponent {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { width, setSidebarVisibility } = this.props;
    if (width !== "xs" && width !== "sm") {
      setSidebarVisibility(true);
    }

    setSidebarVisibility(true);
  }

  handleClose = () => this.props.setSidebarVisibility(false);

  //toggleSidebar = () => this.props.setSidebarVisibility(!this.props.open);

  toggleMenu = () => {
    const { toggleSidebar } = this.props;
    toggleSidebar();
  };

  render() {
    const {
      children,
      classes,
      closedSize,
      open,
      setSidebarVisibility,
      size,
      width,
      ...rest
    } = this.props;

    return (
      <Responsive
        xsmall={
          <Drawer
            variant="temporary"
            open={open}
            PaperProps={{
              className: classes.drawerPaper,
              style: { width: size }
            }}
            onClose={this.toggleSidebar}
            {...rest}
          >
            {cloneElement(Children.only(children), {
              onMenuClick: this.handleClose
            })}
          </Drawer>
        }
        small={
          
          <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
              className: classes.drawerPaper,
              style: {
                width: open ? size : closedSize
              }
            }}
            onClose={this.toggleSidebar}
            {...rest}
          >
            {cloneElement(Children.only(children), {
              dense: true,
              onMenuClick: this.handleClose
            })}
          </Drawer>
        }
        medium={
          <Drawer
          
            variant="permanent"
            open={open}
            PaperProps={{
              className: classes.drawerPaper,
              style: {
                width: open ? size : closedSize,
              }
            }}
            onClose={this.toggleSidebar}
            {...rest}
          >
            <div
              style={{marginTop:"50px"}}
            >
            </div>
            {cloneElement(Children.only(children), { dense: true })}
          </Drawer>
        }
      />
    );
  }
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  closedSize: PropTypes.number,
  open: PropTypes.bool.isRequired,
  setSidebarVisibility: PropTypes.func.isRequired,
  size: PropTypes.number,
  width: PropTypes.string
};

Sidebar.defaultProps = {
  size: DRAWER_WIDTH,
  closedSize: CLOSED_DRAWER_WIDTH
};

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  locale: state.locale // force redraw on locale change
});

export default compose(
  connect(
    mapStateToProps,
    { setSidebarVisibility, toggleSidebar }
  ),
  withStyles(styles),
  withWidth({ resizeInterval: Infinity }) // used to initialize the visibility on first render
)(Sidebar);
