import enMessages from 'ra-language-english'

export default {
  ...enMessages,
  pos: {
    search: 'Search',
    profile: 'Profile',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark'
    },
    dashboard: {
      welcome: {
        title: 'Optiweb CRM',
        subtitle: 'Resistance is futile.'
      }
    }
  },
  menu: {
    userAdmin: 'Super uporabniki',
    users: 'Uporabniki',
  },
  resources: {
    customerPortal:{
      name: "Customer Portal"
    },
    client: {
      name: 'Client'
    },
    contacts: {
      name: 'Contacts'
    },
    clients: {
      name: 'Clients'
    },
    teams: {
      name: 'Teams'
    },
    reports: {
      name: 'Reports'
    },
    technologies: {
      name: 'Technologies'
    },
    servers: {
      name: 'Servers'
    },
    employees: {
      name: 'Employees'
    },
    subscribers:{
      name: 'Client'
    },
    devices: {
      name: 'Devices'
    },
    users: {
      name: 'Users'
    },
    userRoles: {
      name: 'Usergroups'
    },
    projects: {
      name: 'Projects'
    },
    notes: {
      name: 'Notes'
    },
    panels: {
      name: 'Panels'
    },
    addresses:{
      name: 'Addresses'
    },
    notifications:{
      name: 'Notifications'
    },
    customers:{
      name: 'Clients'
    },
    invoices:{
      name: 'Invoices'
    },
    flatrate:{
      name: 'Flatrate'
    },
    jira:{
      name: 'Resource planning'
    },

    misc: {
      fields: {
        phone: 'Phone',
        userRole: 'Uporabniška skupina',
        username: 'Uporabniško ime',
        firstName: 'Ime',
        lastName: 'Priimek',
        email: 'E-pošta',
        subscribers: 'Naročnik',
        client: 'Client',
        buildYear: 'Leto izdelave',
        power: 'Moč',
        manufacturer: 'Znamka',
        model: 'Model',
        workers: 'Ekipa',
        password: 'Geslo',
        password2: 'Geslo',
        active: 'Aktiven',
        pinned: 'Pinned',
        vat: 'Davčna št.',
        permissions: 'Permission',
        name: 'Name',
        search: 'Search',
        icon: 'Icon',
        banner: 'Banner',
        description: 'Description',
        locked: 'Locked',
        enabled: 'Enabled',
        content: 'Content',
        title: 'Title',
        macAddress: 'MAC',
        orderNumber: 'Št. naročila',
        quantity: "Količina",
        birthday: 'Birthday',
        createdAt: 'Created at',
        height: 'Height',
        skintype: 'Skintype',
        weight: 'Weight',
        points: 'Points',
        active: 'Aktiven',
        calibration: 'Calibration',
        city: 'Kraj',
        street: 'Ulica',
        country: 'Država',
        zip: 'Pošta',
        userId: 'User ID',
        sessionType: 'Session type',
        updatedAt: 'Updated at',
        length: 'Length',
        score: 'Score',
        header: 'Header',
        no: 'Ne',
        yes: 'Ja',
        good: 'Dobro',
        cleaningFiltersSpatulasMasks: 'Čiščenje filtrov, loputk in mask',
        desinfectionController: 'Dezinfekcija upravjalnika N.E.',
        testCondention: 'Test kondenčne povezave',
        checkCleaningEvaporator: 'Pregled oz. čiščenje uparjanika Z.E.',
        breathMeasurement: 'Meritev vpiha (°C)',
        pressureMeasurement: 'Meritev tlaka (bar)',
        ratingZd: 'Ocena vzdrževanja',
        gas: 'Hladivo',
        id: 'ID',
        tightness:'Testnost',
        cleanliness: 'Čistost',
        maraeble: 'Zadovoljiva',
        bad: 'Slabo',
        body: 'Body',
        creator: 'Creator',
        contractor: 'Izvajalec',
        sessionUserId: 'Session User ID',
        postImage: 'Post image',
        likeId: 'Like ID',
        commentId: 'Comment ID',
        device: 'Naprava',
        serialNumber: 'Serijska številka',
        parentId: 'Parent ID',
        followId: 'Follow ID',
        type: 'Tip',
        notification_read: 'Read',
        deleted: 'Izbrisano',
        link: 'Link',
        videoId: 'Video ID',
        membership: 'Membership',
        manual: 'Navodila',
        endInstallationDate: 'Konec montaže',
        notes1: 'Opomba 1',
        notes2: 'Opomba 2',
        customerComment: 'Opomba stranke',
        additionalDesc: 'Dodatne opombe',
        orderDate: 'Datum naročila',
        startInstallationDate: 'Začetek montaže',
        status: 'Status',
        customer: 'Stranka',
        address: 'Naslov',
        phonenumber: 'Telefon',
        cName: 'Stranksa ',
        worker: 'Delavec',
        FIELD_IS_REQUIRED: 'Field is required',
        Admin: 'Admin',
        appUser: 'Monter',
        clientCustomer: 'Stranka',
        companyName: 'Podjetje',
      },
      userRoleCategories: {
        cat_ADMINISTRATION: 'Administration',
        cat_OTHER: 'Other'
      }
    }
  }, 
  ROLE_ADMIN: 'Administrator',
  ROLE_CLIENT_ADMIN: 'Mandanten Administrator',
  IS_ADMIN: 'Is Administrator',
  PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC: 'Geslo mora vsebovati: 6 črk, 1 veliko, 1 malo in 1 številko',
  PW_MUST_MATCH: 'Gesli se morata ujemati',
  EMAIL_FORMAT: 'Email Format',
  FIELD_IS_NULL: 'Field is null',
  FIELD_IS_REQUIRED: 'Field is required',
  ERROR: {
    ERROR: 'System error.',
    FIELD_IS_REQUIRED: 'Field is required',
    FIELD_IS_NULL: 'Field is null',
  }
}
