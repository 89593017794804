import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  Filter,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  CardActions,
  CreateButton,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";


export const ClientIcon = Icon;

const ProjectTitle = ({ record }) => {
  return <span>Project {record ? `"${record.name}"` : ""}</span>;
};

const ProjectFilter = props => (
  <Filter {...props}>
          <ReferenceInput
              label="Client"
              sort={{ field: "id", order: "ASC" }}
              source="customer.id"
              reference="customers"
              perPage={10}
              filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            >
            <AutocompleteInput optionText="companyName" />
          </ReferenceInput>
  </Filter>
);

export const ProjectList = props => (
  <List {...props} filters={<ProjectFilter />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="customer.companyName" label="Customer"/>
      <TextField source="hourlyRate" />
      <EditButton label="" /> 
    </Datagrid>
  </List>
);

export const ProjectCreate = props => (
  <Create title="Create a Project"  {...props} >
    <TabbedForm redirect="list">
      <FormTab label="General">
          <TextInput source="name" />
          <SelectInput source="type" choices={[
              { id: 'key', name: 'Project' },
              { id: 'scrum', name: 'Scrum' },
              { id: 'flatrate', name: 'Flatrate' },
          ]} />
          <ReferenceInput
              label="Client"
              sort={{ field: "id", order: "ASC" }}
              source="customer.id"
              reference="customers"
              perPage={10}
              filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            >
            <AutocompleteInput optionText="companyName" />
          </ReferenceInput>
          <ReferenceInput
              label="Contact"
              sort={{ field: "id", order: "ASC" }}
              source="contact.id"
              reference="contacts"
              perPage={10}
              filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="hourlyRate" />
      </FormTab>
      <FormTab label="Tech">
        <ReferenceInput
              label="Server"
              sort={{ field: "id", order: "ASC" }}
              source="server.id"
              reference="servers"
              perPage={300}
              filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput 
              label="Tech"
              sort={{ field: "id", order: "ASC" }}
              source="techIds"
              reference="technologies"
              perPage={300}
              filterToQuery={searchText => ({ name: searchText })}
              allowEmpty
            >
            <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        
        <TextInput source="url"  />
        <TextInput source="devUrl"  />
      </FormTab> 
      <FormTab label="Team">
      <ReferenceArrayInput 
            label="Team Members"
            sort={{ field: "id", order: "ASC" }}
            source="employeeIds"
            reference="employees"
          allowEmpty
          >
          <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      </FormTab>
      <FormTab label="Documents">
        <ArrayInput source="items">
              <SimpleFormIterator inline>
                  <TextInput source="name" helperText={false} />
                  <TextInput source="link" helperText={false} />
              </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="3rd Party">
        <TextInput source="jira"  label="Jira link" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export const ProjectEdit = props => (
  <Edit title="Edit Project" actions={<CardActions />} {...props}>
    <TabbedForm redirect="list">
      <FormTab label="General">
          <TextInput source="name" />
          <SelectInput source="type" choices={[
              { id: 'key', name: 'Project' },
              { id: 'scrum', name: 'Scrum' },
              { id: 'flatrate', name: 'Flatrate' },
          ]} />
          <ReferenceInput
              label="Client"
              sort={{ field: "id", order: "ASC" }}
              source="customer.id"
              reference="customers"
              perPage={10}
              filterToQuery={searchText => ({ name: searchText })}
              allowEmpty
          >
            <AutocompleteInput optionText="companyName" />
          </ReferenceInput>
          <ReferenceInput
              label="Contact"
              sort={{ field: "id", order: "ASC" }}
              source="contact.id"
              reference="contacts"
              perPage={300}
              filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="hourlyRate" />
      </FormTab>
      <FormTab label="Tech">
        <ReferenceInput
              label="Server"
              sort={{ field: "id", order: "ASC" }}
              source="server.id"
              reference="servers"
              perPage={300}
              filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput 
              label="Tech"
              sort={{ field: "id", order: "ASC" }}
              source="techIds"
              reference="technologies"
              allowEmpty
            >
            <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        
        <TextInput source="url"  />
        <TextInput source="devUrl"  />
      </FormTab> 
      <FormTab label="Team">
      <ReferenceArrayInput 
            label="Team Members"
            sort={{ field: "id", order: "ASC" }}
            source="employeeIds"
            reference="employees"
          allowEmpty
          >
          <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      </FormTab>
      <FormTab label="Documents">
        <ArrayInput source="Documents">
              <SimpleFormIterator inline>
                  <TextInput source="name" helperText={false} />
                  <TextInput source="link" helperText={false} />
              </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="3rd Party">
        <TextInput source="jira"  label="Jira link" />
      </FormTab>
    </TabbedForm>
  </Edit>
);