import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import Button from '@material-ui/core/Button';

class redirectOrder extends Component {

    componentDidMount() {
       
    }

    goto = () =>{
        const { push, record } = this.props;
        push('/board/'+record.id);
    }

    render() {
        return (
            <>
            <Button variant='contained' label={""} onClick={this.goto} style={{float:'left', background:"#1e2f39", boxShadow:"0 0 0 white", color:"#fff", textTransform: "capitalize"}}>Aktiven sprint</Button>
        </>
        )
    };
}

redirectOrder.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
};

export default connect(null, {
    push: pushAction,
})(redirectOrder );