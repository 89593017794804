import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  AutocompleteInput,
  TextInput,
  DateInput,
  DateField,
  ReferenceInput,
  SelectInput,
  Filter,
  Button,
  Toolbar,
  DeleteButton,
  SaveButton,
  FormDataConsumer,
  BooleanInput,
  CreateButton
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";
import GoTo from "./GoToPositions";
import PdfDownloadButton from "../../inputs/PdfDownloadButton";
import PdfDownloadEurButton from "../../inputs/PdfDownloadEurButton";
import EditPositionsButton from "../../inputs/EditPositionsButton";

export const ClientIcon = Icon;

const InvoiceTitle = ({ record }) => {
  return <span>Invoice {record ? `"${record.name}"` : ""}</span>;
};

const InvoiceFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Client"
      sort={{ field: "companyName", order: "ASC" }}
      source="customer.id"
      reference="customers"
      perPage={10}
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput optionText="companyName" />
    </ReferenceInput>
    <ReferenceInput
      label="Project"
      sort={{ field: "name", order: "ASC" }}
      source="project.id"
      reference="projects"
      perPage={10}
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Users"
      sort={{ field: "username", order: "ASC" }}
      source="user.id"
      reference="users"
      perPage={70}
      filterToQuery={searchText => ({ username: searchText })}
      allowEmpty
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <SelectInput source="status" choices={[
      { id: 'Open', name: 'Open' },
      { id: 'Confirmed', name: 'Confirmed' },
      { id: 'Ready', name: 'Ready' },
      { id: 'Vasco', name: 'Vasco' },
    ]} />
  </Filter>
);



export const InvoiceList = props => {

  // Set row style based on invoice status
  const invoiceRowStyle = record => {
    if (record.status === 'Ready') {
      return { backgroundColor: 'rgba(191, 191, 63, 0.1)' }; // Transparent yellow
    } else if (record.status === 'Confirmed') {
      return { backgroundColor: 'rgba(63, 127, 191, 0.1)' }; // Transparent blue
    } else if (record.status === 'Vasco') {
      return { backgroundColor: 'rgba(63, 191, 63, 0.1)' }; // Transparent green
    }
    return {}; // Default style
  };


  if (localStorage.getItem('permissions').includes('IS_SALES')) {
    return (
      <List {...props} filters={<InvoiceFilter />} bulkActionButtons={false} actions={<></>}>
        <Datagrid rowStyle={invoiceRowStyle}>
          <TextField source="id" />
          <DateField source="createdAt" />
          <DateField source="workedFrom" />
          <DateField source="workedTo" />
          <TextField source="project.name" label="Project" />
          <TextField source="project.customer.companyName" label="Client (Project)" />
          <TextField source="customer.companyName" label="Client" />
          <TextField source="user" label="User" />
          <TextField source="status" />
          <PdfDownloadButton />
          <PdfDownloadEurButton />
          <EditButton label="" style={{ color: "black" }} />
          <EditPositionsButton />
        </Datagrid>
      </List>
    );
  } else {
    return (
      <List {...props} filters={<InvoiceFilter />} bulkActionButtons={false} >
        <Datagrid rowStyle={invoiceRowStyle}>
          <TextField source="id" />
          <DateField source="createdAt" />
          <DateField source="workedFrom" />
          <DateField source="workedTo" />
          <TextField source="project.name" label="Project" />
          <TextField source="project.customer.companyName" label="Client (Project)" />
          <TextField source="customer.companyName" label="Client" />
          <TextField source="user" label="User" />
          <TextField source="status" />
          <PdfDownloadButton />
          <PdfDownloadEurButton />
          <EditButton label="" style={{ color: "black" }} />
          <EditPositionsButton />
        </Datagrid>
      </List>
    );
  }
};

export const InvoiceCreate = props => {
  const currentDate = new Date();
  const currentDayOfMonth = currentDate.getDate();
  let workedFrom, workedTo;

  // 10th of the month is the cut-off date for invoices in the previous month
  if (currentDayOfMonth < 10) {
    // Set workedFrom and workedTo to previous month's dates
    workedFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    workedTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  } else {
    // Set workedFrom and workedTo to current month's dates
    workedFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    workedTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  }

  // Set workedTo to end of the day
  workedTo.setHours(workedTo.getHours() + 23);
  workedTo.setMinutes(workedTo.getMinutes() + 59);
  workedTo.setSeconds(workedTo.getSeconds() + 59);

  console.log("Worked From: ", workedFrom);
  console.log("Worked To.: ", workedTo);

  // Correct the date to the beginning of the day
  const transformWorkedFrom = (data) => {
    const date = new Date(data);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    console.log("From date (transformed): ", date);
    return date;
  };

  // Correct date to end of the day
  const transformWorkedTo = (data) => {
    const date = new Date(data);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    console.log("To date (transformed): ", date);
    return date;
  };

  // Validate that workedFrom is not greater than workedTo and that they are in the same month
  const validateDates = (values) => {
    const { workedFrom, workedTo, overrideDates } = values;
    const fromDate = new Date(workedFrom);
    const toDate = new Date(workedTo);

    if (!overrideDates) {
      // Validate that workedFrom is not greater than workedTo
      if (new Date(workedFrom) > new Date(workedTo)) {
        return { workedFrom: 'The start date cannot be greater than the end date!' };
      }

      // Validate that workedFrom and workedTo are in the same month
      if (fromDate.getFullYear() !== toDate.getFullYear() || fromDate.getMonth() !== toDate.getMonth()) {
        return { workedTo: 'The accounting period must be in the same month!' };
      }
    }

    return {};
  };

  return (
    <Create title="Create an Invoice" {...props} redirect="editIp">
      <SimpleForm validate={validateDates}>
        <DateInput source="workedFrom" defaultValue={workedFrom} parse={transformWorkedFrom} />
        <DateInput source="workedTo" defaultValue={workedTo} parse={transformWorkedTo}/>
        <BooleanInput source="overrideDates" label="Custom date. Use only with permission!" />
        <FormDataConsumer>
          {({ formData }) => (
            <React.Fragment>
              {!formData.project || !formData.project.id ? (
                <ReferenceInput
                  label="Client"
                  sort={{ field: "companyName", order: "ASC" }}
                  source="customer.id"
                  reference="customers"
                  perPage={250}
                  filterToQuery={(searchText) => ({ name: searchText })}
                  allowEmpty
                >
                  <AutocompleteInput optionText="companyName" />
                </ReferenceInput>
              ) : null}

              {!formData.customer || !formData.customer.id ? (
                <ReferenceInput
                  label="Project"
                  sort={{ field: "name", order: "ASC" }}
                  source="project.id"
                  reference="projects"
                  perPage={250}
                  filterToQuery={(searchText) => ({ name: searchText })}
                  allowEmpty
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              ) : null}
            </React.Fragment>
          )}
        </FormDataConsumer>
        <TextInput source="orderNumber" />
        <DateInput source="valuta" />
      </SimpleForm>
    </Create>
  );
};

export const InvoiceEditIP = props => (
  <Edit title="Edit Invoice" {...props}>
    <GoTo />
  </Edit>
);

export const InvoiceEdit = (props) => {
  // Correct the date to the beginning of the day
  const transformWorkedFrom = (data) => {
    const date = new Date(data);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    console.log("From date (transformed): ", date);
    return date;
  };

  //print current date to console
  const currentDate = new Date();
  console.log("Current date: ", currentDate);

  // Correct date to end of the day
  const transformWorkedTo = (data) => {
    const date = new Date(data);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    console.log("To date (transformed): ", date);
    return date;
  };

  // Validate that workedFrom is not greater than workedTo and that they are in the same month
  const validateDates = (values) => {
    const { workedFrom, workedTo, overrideDates } = values;
    const fromDate = new Date(workedFrom);
    const toDate = new Date(workedTo);

    if (!overrideDates) {
      // Validate that workedFrom is not greater than workedTo
      if (new Date(workedFrom) > new Date(workedTo)) {
        return { workedFrom: 'The start date cannot be greater than the end date!' };
      }

      // Validate that workedFrom and workedTo are in the same month
      if (fromDate.getFullYear() !== toDate.getFullYear() || fromDate.getMonth() !== toDate.getMonth()) {
        return { workedTo: 'The accounting period must be in the same month!' };
      }
    }

    return {};
  };

  return (
    <Edit title="Edit Invoice" {...props}>
      <SimpleForm redirect="list" validate={validateDates}>
        <DateInput source="workedFrom" parse={transformWorkedFrom} />
        <DateInput source="workedTo" parse={transformWorkedTo} />
        <BooleanInput source="overrideDates" label="Custom date. Use only with permission!" />
        <FormDataConsumer>
          {({ formData }) => (
            <React.Fragment>
              {!formData.project || !formData.project.id ? (
                <ReferenceInput
                  label="Client"
                  sort={{ field: "companyName", order: "ASC" }}
                  source="customer.id"
                  reference="customers"
                  perPage={250}
                  filterToQuery={(searchText) => ({ name: searchText })}
                  allowEmpty
                >
                  <AutocompleteInput optionText="companyName" />
                </ReferenceInput>
              ) : null}

              {!formData.customer || !formData.customer.id ? (
                <ReferenceInput
                  label="Project"
                  sort={{ field: "id", order: "ASC" }}
                  source="project.id"
                  reference="projects"
                  perPage={250}
                  filterToQuery={(searchText) => ({ name: searchText })}
                  allowEmpty
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              ) : null}
            </React.Fragment>
          )}
        </FormDataConsumer>

        <TextInput source="orderNumber" />
        <DateInput source="valuta" />
        <GoTo />
      </SimpleForm>
    </Edit>
  );
};