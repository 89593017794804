import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin'
import qs from 'qs'

export const invalidateData = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('clientId')
  localStorage.removeItem('clientName')
  localStorage.removeItem('username')
  localStorage.removeItem('userEmail')
  localStorage.removeItem('userFirstName')
  localStorage.removeItem('userLastName')
  localStorage.removeItem('userRole')
  localStorage.removeItem('permissions')
}

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    const request = new Request('/api/login', {
      method: 'POST',
      // formData: {"username": username, "password": password},
      // body: JSON.stringify({ username, password }),
      body: qs.stringify({
        username: username,
        password: password
      }),
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
    return fetch(request).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      // get user data
      const token = response.headers.get('authorization')

      localStorage.setItem('token', token)

      const identityRequest = new Request('/api/identity', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: token
        })
      })

      return fetch(identityRequest)
        .then(response => response.json())
        .then(json => {
          if (response.status < 200 || response.status >= 300) {
            localStorage.removeItem('token')
            throw new Error(response.statusText)
          }

          localStorage.setItem('userId', json.identity.id)
          localStorage.setItem('clientId', json.identity.clientId)
          localStorage.setItem('clientName', json.identity.clientName)
          localStorage.setItem('username', json.identity.username)
          localStorage.setItem('userEmail', json.identity.userEmail)
          localStorage.setItem('userFirstName', json.identity.userFirstName)
          localStorage.setItem('userLastName', json.identity.userLastName)
          localStorage.setItem('userFullName', json.identity.userFullName)
          localStorage.setItem(
            'userRoles',
            JSON.stringify(json.identity.userRoles)
          )
          localStorage.setItem('locale', 'de')
          localStorage.setItem(
            'permissions',
            JSON.stringify(json.identity.permissions)
          )

          return Promise.resolve({
            userData: {
              id: json.id,
              username: json.username,
              userEmail: json.email,
              userFullName: json.firstName + ' ' + json.lastName
            }
          })
        })
    })
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    invalidateData()
    return Promise.resolve()
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      invalidateData()
      return Promise.reject()
    }
    return Promise.resolve()
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    return Promise.resolve(permissions)
  }

  return Promise.reject('Unknown method')
}
