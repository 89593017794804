// React imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import {
  translate,
  DashboardMenuItem,
  MenuItemLink,
  Responsive,
  WithPermissions
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import MenuElem from "./MenuElem";
import AppsIcon from '@material-ui/icons/Apps';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import DevicesIcon from '@material-ui/icons/Devices';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from "@material-ui/icons/ViewList";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import NoteIcon from "@material-ui/icons/Note";

const styles = {
  root: {
    color: "#fff"
  },
  active: {
    color: "#000"
  }
};
const items = [
  {
    name: null,
    icon: null,
    type: "divider",
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"]
  },
  {
    name: "users",
    icon: <PersonIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"]
  },
  {
    name: "userRoles",
    icon: <GroupIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"]
  },
  {
    name: null,
    icon: null,
    type: "divider",
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN", "DRIVER_LIST"]
  },  
  {
    name: "employees",
    icon: <PersonIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"]
  },
  {
    name: "teams",
    icon: <GroupIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"]
  },
  {
    name: null,
    icon: null,
    type: "divider",
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN", "DRIVER_LIST"]
  },
  {
    name: "projects",
    icon: <AppsIcon />,
    permissions: ["IS_ADMIN", "IS_PM"]
  },
  {
    name: "customers",
    icon: <PersonPinIcon />,
    permissions: ["IS_ADMIN", "IS_PM"]
  },
  {
    name: "contacts",
    icon: <PhoneIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"],
    permissions: ["IS_ADMIN", "IS_PM"]
  },
  {
    name: "notes",
    icon: <NoteIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"],
    permissions: ["IS_ADMIN", "IS_PM"]
  },
  {
    name: null,
    icon: null,
    type: "divider",
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN", "DRIVER_LIST"]
  },
  {
    name: "invoices",
    icon: <ContentCopyIcon />,
  },
  {
    name: "flatrate",
    icon: <ViewListIcon />,
    permissions: ["IS_ADMIN", "IS_PM"]
  },
  {
    name: null,
    icon: null,
    type: "divider",
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN", "DRIVER_LIST"]
  },
  {
    name: "technologies",
    icon: <DevicesIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"]
  },
  {
    name: "servers",
    icon: <DevicesIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN"]
  },
  {
    name: null,
    icon: null,
    type: "divider",
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN", "DRIVER_LIST"]
  },

  {
    name: "jira",
    icon: <EventAvailableIcon />,
    permissions: ["IS_ADMIN", "IS_PM"]
  },

  {
    name: "reports",
    icon: <FileDownloadIcon />,
    permissions: ["IS_ADMIN", "IS_CLIENT_ADMIN", "DRIVER_LIST"],
    permissions: ["IS_ADMIN", "IS_PM"]
  },  
  {
    name: "customerPortal",
    icon: <AppsIcon />,
    permissions: ["IS_ADMIN", "IS_PM"]
  },

];

const profile = "/profile/" + localStorage.getItem("userId");

const Menu = ({ onMenuClick, translate, logout, classes }) => {
  return (
    <WithPermissions
      render={({ permissions }) => (
        <div>
          <MenuElem
            items={items}
            onMenuClick={onMenuClick}
            translate={translate}
            logout={logout}
            profile={profile}
          />
          <Responsive
            xsmall={
              <MenuItemLink
                to={profile}
                primaryText={translate("pos.profile")}
                leftIcon={<SettingsIcon />}
                onClick={onMenuClick}
              />
            }
            medium={null}
          />
          <Responsive xsmall={logout} medium={null} />
        </div>
      )}
    />
  );
};

const enhance = compose(
  withRouter,
  connect(
    state => ({
      theme: state.theme,
      locale: state.i18n.locale
    }),
    {}
  ),
  translate,
  withStyles(styles)
);

export default enhance(Menu);
