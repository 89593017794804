import React from "react";
import {
  Create,
  Edit,
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  TextInput,
  ReferenceInput,
  SelectInput,
  Filter,
  TabbedForm,
  FormTab,
  Responsive,
  SimpleList,
  translate,
  FunctionField,
  ListButton,
  CardActions,
  EditButton,
  DeleteButton
} from "react-admin";

import CrossIcon from "@material-ui/icons/LocalTaxi";
import CheckIcon from "@material-ui/icons/LocalTaxi";

import Icon from "@material-ui/icons/LocalTaxi";

export const UserRoleIcon = Icon;

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const required = (message = "FIELD_IS_REQUIRED") => value =>
  value ? undefined : message;

const minLength3 = (message = "FIELD_MINLENGTH_3") => value =>
  value && value.length >= 3 ? undefined : message;

const UserRoleRenderer = translate(({ translate, record }) => {
  return <span>{translate(record.name)}</span>;
});

const Actions = ({ permissions, ...props }) => {
  return (
    <CardActions style={cardActionStyle}>
      {permissions &&
      (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
        permissions.indexOf("IS_ADMIN") >= 0) ? (
        <EditButton record={props.data} basePath={props.basePath} />
      ) : null}
      <ListButton {...props} />
      {permissions &&
      (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
        permissions.indexOf("IS_ADMIN") >= 0) ? (
        <DeleteButton
          record={props.data}
          basePath={props.basePath}
          resource={props.resource}
        />
      ) : null}
    </CardActions>
  );
};

const UserRoleFilter = props => (
  <Filter {...props}>

    <TextInput
      elStyle={{ width: 380 }}
      label="resources.misc.fields.userRole"
      source="name"
    />
  </Filter>
);

export const UserRoleList = ({ permissions, ...props }) => (
  <List
    title="resources.userRoles.list"
    filters={<UserRoleFilter />}
    sort={{ field: "id", order: "DESC" }}
    perPage={25}
    {...props}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.name}
          leftIcon={record => (record.active ? <CheckIcon /> : <CrossIcon />)}
        />
      }
      medium={
        <Datagrid>
          <FunctionField
            {...props}
            label="resources.misc.fields.userRole"
            render={record => <UserRoleRenderer record={record} />}
          />
          {permissions &&
          (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
            permissions.indexOf("IS_ADMIN")) >= 0 ? (
            <EditButton {...props} />
          ) : null}
        </Datagrid>
      }
    />
  </List>
);

const PermissionListRendererUl = translate(({ translate, record }) => {
  return <li>{translate(record)}</li>;
});

const UserRoleShowTitle = ({ record }) => (
  <span>
    {translate("resources.userRole.show", {
      name: record.name
    })}
  </span>
);

export const UserRoleShow = ({ permissions, ...props }) => {
  return (
    <Show
      title={<UserRoleShowTitle />}
      actions={<Actions permissions={permissions} {...props} />}
      {...props}
    >
      <SimpleShowLayout>
        <TextField label="resources.misc.fields.name" source="name" />
        <FunctionField
          label="resources.misc.fields.permissions"
          render={record => (
            <ul>
              {record.permissions.map((permission, pi) => (
                <PermissionListRendererUl key={pi} record={permission} />
              ))}
            </ul>
          )}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export const UserRoleCreate = ({ permissions, ...props }) => (
  <Create title="resources.userRole.create" {...props}>
    <TabbedForm redirect="list">
      <FormTab label="resources.misc.tabs.userRoleData">
        <TextInput
          label="resources.misc.fields.userRole"
          source="name"
          validate={[required(), minLength3()]}
        />

        
      </FormTab>
    </TabbedForm>
  </Create>
);

const UserRoleEditTitle = ({ record }) => (
  <span>
    {translate("resources.userRole.edit", {
      name: "" + record.name
    })}
  </span>
);

export const UserRoleEdit = ({ permissions, ...props }) => {
  return (
    <Edit title={<UserRoleEditTitle />} actions={<CardActions />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="resources.misc.tabs.userRoleData">
          <TextInput
            label="resources.misc.fields.userRole"
            source="name"
            validate={[required(), minLength3()]}
          />

         
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
