import { put, takeEvery } from 'redux-saga/effects'
import {
  USER_LOGIN_SUCCESS,
  USER_CHECK,
  CRUD_UPDATE_SUCCESS
} from 'react-admin'
import { SET_IDENTITY } from '../reducer/actions'

function * checkAuth (action) {
  if (typeof action.payload !== 'undefined') {
    yield put({ type: SET_IDENTITY, payload: action.payload })
  }
}

function * checkAuthFromStorage (action) {
  yield put({
    type: SET_IDENTITY,
    payload: {
      userData: {
        id: localStorage.getItem('userId'),
        clientId: localStorage.getItem('clientId'),
        clientName: localStorage.getItem('clientName'),
        username: localStorage.getItem('username'),
        userRole: localStorage.getItem('userRole'),
        userEmail: localStorage.getItem('userEmail'),
        userFullName: localStorage.getItem('userFullName')
      }
    }
  })
}

function * updateAuth (action) {
  if (action.meta.resource === 'profile') {
    // profile action includes gas station and locale

    yield put({
      type: SET_IDENTITY,
      payload: {
        userData: {}
      }
    })
  }
}

export default function * authSaga () {
  yield takeEvery(USER_LOGIN_SUCCESS, checkAuth)
  yield takeEvery(USER_CHECK, checkAuthFromStorage)
  yield takeEvery(CRUD_UPDATE_SUCCESS, updateAuth)
}
