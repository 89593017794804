import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, SubmissionError, change } from 'redux-form';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import axios from 'axios';
import { Button, WithPermissions } from "react-admin";
import IconContentSend from "@material-ui/icons/Send";


class Issue extends Component {
    state = {
        issue: null,
        comment: [],
        body:""
    };

    componentDidMount = () => {
        this.getIssues();
        this.getComment();
    };
   
    getIssues = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: "/api/jira/issue/" + this.props.match.params.id,
            method: 'get',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            this.setState({ issue: response.data });
            console.log(response.data)
        }).catch((error) => {
            console.log(error);
        });
    };

    getComment = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: "/api/jira/comment/" + this.props.match.params.id,
            method: 'get',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            this.setState({ comment: response.data.comments });
        }).catch((error) => {
            console.log(error);
        });
    };

    comment = () => {
        const authToken = localStorage.getItem('token');
        axios({
            url: "/api/jira/sendComment/" + this.props.match.params.id,
            method: 'post',
            data: { body: this.state.body },
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            this.setState({body:""})
            this.getComment();
        }).catch((error) => {
            console.log(error);
        });
    };

    onChange = (e) => {
        this.setState({body:e.target.value})
    }

    render() {
        return (
            <div>
                <div style={{ display:"table", background: "#fff", borderRadius: "3px 3px 3px 3px", padding: "25px", height:"1000px", width:"100%"}}>
                {(this.state.issue !== null) ? (
                    <div style={{ width:"700px"}}>
                        <h1 style={{ color: "grey" }}>{this.state.issue.fields.summary}</h1>
                        <br/><br/>
                        {(Number(this.state.issue.fields.customfield_10052)>0) ? (
                        <span> <b>Estimacija: </b>{this.state.issue.fields.customfield_10052} h</span>
                        ) : (<></>)}<br/>
                        <span><b>Status: </b> {this.state.issue.fields.status.name}</span><br/><br/>
                        <span><b>Opis:</b></span>
                        <p>
                            <div className="content" dangerouslySetInnerHTML={{__html: this.state.issue.renderedFields.description.replaceAll('src="','src="https://optiweb.atlassian.net/')}}></div>
                        </p><br/>
                        <span><b>Komentarji:</b> </span><br/><br/>
                        <br/>
                        <div>
                        {(this.state.comment.length>0) ? (
                            this.state.comment.map((c, index) => (
                                (c.visibility == null ) ? (
                                <div style={{border:"1px solid #fff", borderRadius:"5px 5px 5px", padding:"10px", width:"80%", marginBottom:"10px"}}>
                                   <span style={{fontSize:"12px"}}>{c.created} </span><br/>
                                   <b> {c.author.displayName}</b>  {(c.visibility) ? c.visibility.value: ""} <br/><br/>
                                   <div className="content" dangerouslySetInnerHTML={{__html: c.renderedBody.replaceAll('src="','src="https://optiweb.atlassian.net/')}}></div>
                                   <br/><br/>
                                    <hr style={{border:"1px solid #e9e9e9", width: "80%", float: "left"}} />
                                </div>
                                ) : (<></>)
                            ))
                        ) : (<></>)}
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <textarea cols="70" rows="10" style={{border:"1px solid #cdc9c9",borderRadius: "3px 3px 3px 3px",padding:"10px"}} onChange={(e)=>this.onChange(e)} value={this.state.body} /><br/>
                        <Button label="Potrdi" onClick={() => this.comment()} style={{ "color": "green"}}>
                            <IconContentSend style={{ "color": "green" }} />
                        </Button>
                    </div>
                ) : (<></>)}
                </div>
            </div>
        );
    }
}

Issue.propTypes = {
    ...propTypes,
    push: PropTypes.func,
    theme: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

Issue.defaultProps = {
    theme: {},
};

export default connect(null, {
    push: pushAction,
})(Issue);
