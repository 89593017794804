import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FlatButton from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import axios from 'axios';
import { translate } from 'react-admin';
import download from 'downloadjs';
import { refreshView as refreshViewAction } from 'react-admin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import FileDownload from "@material-ui/icons/FileDownload";

class PdfDownloadEurButton extends Component {
    state = {
        open: false,
    };


    startDownload = () => {
        const { record, recordId, fileName, controller, refreshView } = this.props;
        console.log(record);

        const authToken = localStorage.getItem('token');
        let url = '/api/invoices/pdfreporteur/'+record.id;

        axios({
            url: url,
            method: 'get',
            responseType: 'blob',
            headers: {
                Authorization: authToken,
                Accept: 'application/json',
            },
        }).then(response => {
            let filename = 'report.pdf';
            download(response.data, filename);
            refreshView();
        }).catch((error) => {
            console.log(error);
        });
    };

    render() {
        const {translate,label,icon,record,controller} = this.props;
       
        return (
            <div>
                <Button variant='contained' label={translate(label)} onClick={this.startDownload} style={{float:'left', background:"none", boxShadow:"0 0 0 white"}}> < FileDownload /> € </Button>
            </div>
        );
    };
}

PdfDownloadEurButton.propTypes = {
    record: PropTypes.object,
    recordId: PropTypes.number,
    fileName: PropTypes.string,
    label: PropTypes.string,
    controller: PropTypes.string,
};

const enhance = compose(
    translate,
    connect(null, {
        refreshView: refreshViewAction,
    }),
);

export default enhance(PdfDownloadEurButton);