import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, SubmissionError, change } from 'redux-form';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import axios from 'axios';


class board extends Component {
    state = {
        issues: [],
        invoice: [],
        price: 60,
        ow: 0,
        sum: 0,
        combinedText: "Programiranje",
        permissions: "",
        teams: [],
        makeChild: "",
        parent: "",
        reportNeeded: false,
        note: "",
        selectedId: 0
    };

    componentDidMount = () => {
        this.getIssues();
    };

   
    getIssues = (onlyFlatrates) => {
        const authToken = localStorage.getItem('token');
        axios({
            url: "/api/jira/board/" + this.props.match.params.id,
            method: 'get',
            headers: {
                Authorization: authToken,
            },
        }).then(response => {
            let issuesWL = this.state.issues;
            console.log(response.data);
            response.data.issues.forEach(i => {
                issuesWL.push({ name: i.fields.summary, status: i.fields.status.name, time:i.fields.customfield_10052, key: i.key})
            })
            this.setState({ issues: issuesWL });
        }).catch((error) => {
            console.log(error);
        });
    };

    render() {

        return (
            <div>
                <div style={{ display:"table", background: "#f6f6f6", borderRadius: "3px 3px 3px 3px", padding: "15px", height:"1000px", width:"90%"}}>


                    <div style={{width:"23%",  borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    <h2 style={{ color: "grey" }}>TODO</h2>
                    </div>
                    <div style={{width:"23%",  borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    <h2 style={{ color: "grey" }}>In Progress</h2>
                    </div>
                    <div style={{width:"23%",  borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    <h2 style={{ color: "grey" }}>In Customer Review</h2>
                    </div>
                    <div style={{width:"23%",  borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    <h2 style={{ color: "grey" }}>Done</h2>
                    </div>

                    <div style={{width:"23%", borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    {this.state.issues.map((i, index) => (
                        (i.status === "To Do" || i.status === "Pending") ? (
                            <div style={{background: "#fff", width:"90%", border:"1px solid #cdc9c9", borderRadius:"5px 5px 5px", padding:"10px", marginBottom:"15px"}}>
                                <span style={{fontSize:"14px"}}>{i.status}</span>  <br/>
                                <a style={{color:"#3b3b3b", textDecoration:"none", fontWeight:"600"}} href={"javascript:void()"} onClick={()=>this.props.push("/issue/"+i.key)}>{i.name}</a>
                                <br/><br/>
                                {(i.time>0) ? (
                                <span>Estimacija: {i.time} h</span>  
                                ) : (<></>)}
                                <br/><br/><br/>
                                <span style={{color:"#959595"}}>{i.key}</span>   
                            </div>
                        ) : (<></>)
                    )
                    )}
                    </div>

                    <div style={{width:"23%",  borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    {this.state.issues.map((i, index) => (
                        (i.status === "In Progress" || i.status === "Read for Testing" || i.status === "In QA") ? (
                            <div style={{background: "#fff", width:"90%", border:"1px solid #cdc9c9", borderRadius:"5px 5px 5px", padding:"10px", marginBottom:"15px"}}>
                                <span style={{fontSize:"14px"}}>{i.status}</span>  <br/>
                                <a style={{color:"#3b3b3b", textDecoration:"none", fontWeight:"600"}} href={"javascript:void()"} onClick={()=>this.props.push("/issue/"+i.key)}>{i.name}</a>
                                <br/><br/>
                                {(i.time>0) ? (
                                <span>Estimacija: {i.time} h</span>  
                                ) : (<></>)}
                                <br/><br/><br/>
                                <span style={{color:"#959595"}}>{i.key}</span>    
                            </div>
                        ) : (<></>)
                    )
                    )}
                    </div>

                    <div style={{width:"23%",  borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    {this.state.issues.map((i, index) => (
                        (i.status === "In Customer Review") ? (
                            <div style={{ background: "#fff", width:"90%", border:"1px solid #cdc9c9", borderRadius:"5px 5px 5px", padding:"10px", marginBottom:"15px"}}>
                                <span style={{fontSize:"14px"}}>{i.status}</span>  <br/>
                                <a style={{color:"#3b3b3b", textDecoration:"none", fontWeight:"600"}} href={"javascript:void()"} onClick={()=>this.props.push("/issue/"+i.key)}>{i.name}</a>
                                <br/><br/>
                                {(i.time>0) ? (
                                <span>Estimacija: {i.time} h</span>  
                                ) : (<></>)} 
                                <br/><br/><br/>
                                <span style={{color:"#959595"}}>{i.key}</span>    
                            </div>
                        ) : (<></>)
                    )
                    )}
                    </div>

                    <div style={{width:"23%",  borderRadius:"3px 3px 3px", padding:"10px", float:"left"}}>
                    {this.state.issues.map((i, index) => (
                        (i.status === "Done") ? (
                            <div style={{background: "#fff", width:"90%", border:"1px solid #cdc9c9", borderRadius:"5px 5px 5px", padding:"10px", marginBottom:"15px"}}>
                                <span style={{fontSize:"14px"}}>{i.status}</span>  <br/>
                                <a style={{color:"#3b3b3b", textDecoration:"none", fontWeight:"600"}} href={"javascript:void()"} onClick={()=>this.props.push("/issue/"+i.key)}>{i.name}</a>
                                <br/><br/>
                                {(i.time>0) ? (
                                <span>Estimacija: {i.time} h</span>  
                                ) : (<></>)}
                                <br/><br/><br/>
                                <span style={{color:"#959595"}}>{i.key}</span>  
                            </div>
                        ) : (<></>)
                    )
                    )}
                    </div>

                    
                
                </div>
            </div>
        );
    }
}

board.propTypes = {
    ...propTypes,
    push: PropTypes.func,
    theme: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

board.defaultProps = {
    theme: {},
};

export default connect(null, {
    push: pushAction,
})(board);
