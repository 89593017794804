// React imports
import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

// React Admin imports
import { UserMenu, MenuItemLink, translate, RefreshButton } from "react-admin";

// Material imports
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

// Icon imports
import SettingsIcon from "@material-ui/icons/Settings";
import RefreshIcon from "@material-ui/icons/Refresh";
import Person from "@material-ui/icons/Person";
import ExpandMore from "@material-ui/icons/ExpandMore";

export const drawerWidth = 240;
export const closedDrawerWidth = 55;

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  },
  userBar: {
    display: "inline-flex"
  },
  customAppBar: {
    backgroundColor: "#fff"
  },
  toolBar: {
    padding: "0 5px"
  },
  logo: {
    paddingLeft: "10px"
  }
};

const UserMenuButton = props => (
  <IconButton edge="end" aria-label="openusermenu" style={{ color: "#fff" }}>
    <ExpandMore />
  </IconButton>
);

class CustomAppBar extends Component {
  render() {
    const { identity, translate } = this.props;
    let userId = 0;
    var userName = "Janez Novak";
    var userEmail = "janez@novak.com";

    if (
      identity !== null &&
      identity.userData !== null &&
      identity.userData !== undefined
    ) {
      userId = identity.userData.id;
      userName = identity.userData.userFullName;
      userEmail = identity.userData.userEmail;
    }

    return (
      <AppBar position="static" {...this.props} style={{ backgroundColor: "#1e2f39", color: "#fff" }}>
        <Toolbar style={styles.toolBar}>
          <div style={styles.logo}>
            <img src="images/ow.png" height="40px" alt="" />
          </div>

          <span style={styles.spacer} />

          <RefreshButton
            style={{ color: "#fff",display:"none" }}
            icon={<RefreshIcon />}
            label={""}
          />

          <div>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="caption"
                      style={{ color: "#fff", paddingRight: "10px" }}
                    >
                      {userName}
                    </Typography>
                  }
                 
                />
                <ListItemSecondaryAction>
                  <UserMenu
                    {...this.props}
                    icon={<UserMenuButton {...this.props} />}
                  >
                    <MenuItemLink
                      to={"profile/" + userId}
                      primaryText={translate("pos.profile")}
                      leftIcon={<SettingsIcon />}
                    />
                  </UserMenu>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = state => ({
  identity: state.identity
});

const enhance = compose(
  translate,
  connect(mapStateToProps)
);

export default enhance(CustomAppBar);
