import React, { Component } from "react";
import { SelectArrayInput, GET_LIST } from "react-admin";
import restClient from "../providers/dataProvider/rest";

class RoleSelectGroupInput extends Component {
  state = {
    options: []
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dependsOnValue !== this.props.dependsOnValue) {
      this.fetchData(nextProps);
    }
  }

  fetchData(props) {
    console.log("fetch data");
    this.fetchPermissions().then(options => {
      this.setState({ options: options }, () => {
        console.log("NEW STATE", this.state);
      });
    });
  }

  fetchPermissions = () => {
    return new Promise((resolve, reject) => {
      restClient(GET_LIST, "userRoles", {
        pagination: { page: 0, perPage: 25 },
        sort: { field: "", order: "" },
        filter: {}
      })
        .then(data => {
          console.log("DATA", data);
          resolve(data.data);
        })
        .catch(e => {
          reject(null);
        });
    });
  };

  required = (message = "FIELD_IS_REQUIRED") => value =>
    value ? undefined : message;

  render() {
    return (
      <SelectArrayInput
        {...this.props}
        choices={this.state.options}
        optionValue="id"
        filter={{ name: "ROLE_CLIENT_" }}
        allowEmpty
      />
    );
  }
}

RoleSelectGroupInput.propTypes = SelectArrayInput.propTypes;
RoleSelectGroupInput.defaultProps = SelectArrayInput.defaultProps;

export default RoleSelectGroupInput;
