import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  ReferenceInput,
  SelectInput,
  translate,
  FunctionField,
  Show,
  SimpleShowLayout,
  CardActions,
  ListButton,
  Filter
} from "react-admin";
import RoleSelectGroupInput from "../../inputs/RoleSelectGroupInput";
import Icon from "@material-ui/icons/LocalTaxi";
import {
  required,
  password,
  passwordEdit,
  passwordEquals
} from "../../validators/validators";

export const UserIcon = Icon;

const UserRoleOptionRenderer = translate(({ translate, record }) => (
  <span>{translate(record.name)}</span>
));

const UserRoleListRenderer = translate(({ translate, record }) => {
  return (
    <span style={{ display: "block", marginRight: "3px" }}>
      {translate(record.name)}
    </span>
  );
});

const UserRoleListRendererUl = translate(({ translate, record }) => {
  return <li>{translate(record.name)}</li>;
});

const PermissionListRendererUl = translate(({ translate, record, parent }) => {
  return (
    <li>
      {translate(record)} ({translate(parent.name)})
    </li>
  );
});

const UserTitle = ({ record }) => {
  return (
    <span>User {record ? `"${record.firstName} ${record.lastName}"` : ""}</span>
  );
};

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const UserFilter = props => (
  <Filter {...props}>
    <TextInput
      elStyle={{ width: 380 }}
      label="resources.misc.fields.username"
      source="username"
    />
    <ReferenceInput
      label="resources.misc.fields.userRole"
      source="userRolePk"
      reference="userRoles"
      filter={{ name: "ROLE_CLIENT_" }}
    >
      <SelectInput optionText={<UserRoleOptionRenderer />} optionValue="id" />
    </ReferenceInput>
    <TextInput label="resources.misc.fields.firstName" source="firstName" />
    <TextInput label="resources.misc.fields.lastName" source="lastName" />
    <BooleanInput
      label="resources.misc.fields.active"
      source="active"
      options={{ defaultToggled: true }}
    />
  </Filter>
);

export const UserList = props => (
  <List {...props} filters={<UserFilter />} bulkActionButtons={false}>
    <Datagrid>
      <TextField label="resources.misc.fields.username" source="username" />
      <FunctionField
        label="resources.misc.fields.userRole"
        render={record =>
          record.userRoles &&
          record.userRoles.map((role, index) =>
            role.deleted ? null : (
              <UserRoleListRenderer key={role.id} record={role} />
            )
          )
        }
      />
      <TextField label="resources.misc.fields.firstName" source="firstName" />
      <TextField label="resources.misc.fields.lastName" source="lastName" />
      <BooleanField label="resources.misc.fields.active" source="active" />
      <EditButton label="" /> 
    </Datagrid>
  </List>
);

const Actions = ({ basePath, data, refresh }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    {/*<DeleteButton basePath={basePath} record={data} />*/}
  </CardActions>
);

export const UserShow = props => (
  <Show title={<UserTitle />} actions={<Actions />} {...props}>
    <SimpleShowLayout>
      <TextField label="resources.misc.fields.username" source="username" />
      <TextField label="resources.misc.fields.firstName" source="firstName" />
      <TextField label="resources.misc.fields.lastName" source="lastName" />
      <FunctionField
        label="resources.misc.fields.userRole"
        render={record => (
          <ul>
            {record.userRoles.map((role, index) => (
              <UserRoleListRendererUl
                key={role.id}
                record={role}
                isLast={!(index < record.userRoles.length - 1)}
              />
            ))}
          </ul>
        )}
      />
      
      <FunctionField
        label="resources.misc.fields.permissions"
        render={record => (
          <ul>
            {record.userRoles.map((role, index) =>
              role.permissions.map((permission, pi) => (
                <PermissionListRendererUl
                  key={pi}
                  record={permission}
                  parent={role}
                />
              ))
            )}{" "}
          </ul>
        )}
      />
    </SimpleShowLayout>
  </Show>
);

export const UserEdit = ({ permissions, ...props }) => (
  <Edit title={<UserTitle />} actions={<CardActions />} {...props}>
    <SimpleForm>
      <TextInput
        disabled
        label="resources.misc.fields.username"
        source="username"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.firstName"
        source="firstName"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.lastName"
        source="lastName"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.email"
        source="email"
        validate={[required()]}
      />
      <RoleSelectGroupInput
        source="userRoleIds"
        reference="userRoles"
        label="resources.misc.fields.userRole"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.password"
        source="password"
        type="password"
        validate={[passwordEdit()]}
      />
      <TextInput
        label="resources.misc.fields.password2"
        source="password2"
        type="password"
        validate={[passwordEquals()]}
      />
     
      <BooleanInput
        label="resources.misc.fields.active"
        source="active"
        options={{ defaultToggled: true }}
      />
    </SimpleForm>
  </Edit>
);

export const UserCreate = ({ permissions, ...props }) => (
  <Create title="Create a User" {...props}>
    <SimpleForm redirect="list">
    <TextInput
        label="resources.misc.fields.firstName"
        source="firstName"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.lastName"
        source="lastName"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.username"
        source="username"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.email"
        source="email"
        validate={[required()]}
      />
      <RoleSelectGroupInput
        source="userRoleIds"
        reference="userRoles"
        label="resources.misc.fields.userRole"
        validate={[required()]}
      />
      <TextInput
        label="resources.misc.fields.password"
        source="password"
        type="password"
        validate={[passwordEdit()]}
      />
      <TextInput
        label="resources.misc.fields.password2"
        source="password2"
        type="password"
        validate={[passwordEquals()]}
      />
     
      <BooleanInput
        label="resources.misc.fields.active"
        source="active"
        options={{ defaultToggled: true }}
      />
    </SimpleForm>
  </Create>
);
