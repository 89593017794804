import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { translate } from "react-admin";

const styles = {
  media: {
    height: "18em"
  }
};

const Welcome = ({ classes, translate }) => (
  <div>
    <Typography variant="headline" component="h2">
      {translate("pos.dashboard.welcome.title")}
    </Typography>
    <Typography component="p">
      {translate("pos.dashboard.welcome.subtitle")}
    </Typography>
  </div>
);

export default withStyles(styles)(translate(Welcome));
