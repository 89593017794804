import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  Filter,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  CardActions,
  CreateButton,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";
import GoTo from "./GoToScrum";


export const ClientIcon = Icon;

const ProjectTitle = ({ record }) => {
  return <span>Project {record ? `"${record.name}"` : ""}</span>;
};

const ProjectFilter = props => (
  <Filter {...props}>
          <ReferenceInput
              label="Client"
              sort={{ field: "id", order: "ASC" }}
              source="customer.id"
              reference="customers"
              perPage={10}
              filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            >
            <AutocompleteInput optionText="companyName" />
          </ReferenceInput>
  </Filter>
);

export const ProjectCpList = props => (
  <List {...props} filters={<ProjectFilter />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="customer.companyName" label="Customer"/>
      <GoTo />
    </Datagrid>
  </List>
);
