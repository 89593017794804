import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  EditButton,
  TextInput,
  CardActions,
  TextField,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";

export const ClientIcon = Icon;

export const EmployeeList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="team.name" />
        <EditButton label="" /> 
    </Datagrid>
  </List>
);

export const EmployeeCreate = props => (
  <Create title="Create a Employee" {...props} >
    <SimpleForm redirect="list">
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput source="phone" />
        <TextInput source="price" />
        <TextInput source="availability" />
        <TextInput source="worktimeID" />
        <ReferenceInput
            label="Team"
            sort={{ field: "id", order: "ASC" }}
            source="team.id"
            reference="teams"
          allowEmpty
          >
          <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const EmployeeEdit = props => (
  <Edit title="Edit Employee" actions={<CardActions />} {...props}>
    <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput source="phone" />
        <TextInput source="price" />
        <TextInput source="availability" />
        <TextInput source="worktimeID" />
        <ReferenceInput
            label="Team"
            sort={{ field: "id", order: "ASC" }}
            source="team.id"
            reference="teams"
          allowEmpty
          >
          <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);