// React imports
import React, { Component } from "react";
import { propTypes, reduxForm, Field } from "redux-form";
import { connect } from "react-redux";

// Other imports
import PropTypes from "prop-types";
import compose from "recompose/compose";

// Material Ui imports
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

// React Admin imports
import { Notification, translate, userLogin } from "react-admin";

// Style imports
import LoginStyles from "./theme/styles/LoginStyles";

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class Login extends Component {
  login = auth =>
    this.props.userLogin(
      auth,
      this.props.location.state ? this.props.location.state.nextPathname : "/"
    );

  render() {
    const { classes, handleSubmit, isLoading, translate } = this.props;
    return (
      <div className={classes.main} style={{background:"url(images/bg.png)", backgroundSize:"cover"}}>
        <Card className={classes.card} style={{borderRadius:"4px",marginTop:"30vh"}}>
          <form onSubmit={handleSubmit(this.login)}>
            <CardContent>
              <div className={classes.form}>
                <div className={classes.logo}>
                  <img src="images/logo.png" width="250px" alt="" />
                </div>
                <div className={classes.input}>
                  <Field
                    name="username"
                    component={renderInput}
                    label={translate("ra.auth.username")}
                    disabled={isLoading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    component={renderInput}
                    label={translate("ra.auth.password")}
                    type="password"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </CardContent>

            <CardActions className={classes.actions}>
              <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
                fullWidth
              >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate("ra.auth.sign_in")}
              </Button>
            </CardActions>
          </form>
        </Card>
        <Notification />
      </div>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  classes: PropTypes.object,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  translate,
  reduxForm({
    form: "signIn",
    validate: (values, props) => {
      const errors = {};
      const { translate } = props;
      if (!values.username) {
        errors.username = translate("ra.validation.required");
      }
      if (!values.password) {
        errors.password = translate("ra.validation.required");
      }
      return errors;
    }
  }),
  connect(
    mapStateToProps,
    { userLogin }
  ),
  withStyles(LoginStyles)
);

export default enhance(Login);
