import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  Filter,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  CardActions,
  CreateButton,
  SelectInput,
  ReferenceInput
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";

export const ClientIcon = Icon;

export const TeamList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="name" />
      <EditButton label="" /> 
    </Datagrid>
  </List>
);

export const TeamCreate = props => (
  <Create title="Create a Team" {...props} >
    <SimpleForm redirect="list">
        <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const TeamEdit = props => (
  <Edit title="Edit Team" actions={<CardActions />} {...props}>
    <SimpleForm>
        <TextInput source="name" />
    </SimpleForm>
  </Edit>
);