import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, SubmissionError, change } from 'redux-form';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Delete from '@material-ui/icons/DoNotDisturb';
import Paper from '@material-ui/core/Paper';
import { arrayOfDeffered } from 'redux-saga/utils';


class Jira extends Component {

   state = { 
     fromDate: "",
     toDate: "",
     employee: [], 
     employee14: [], 
     avail: [],
     searching:false
   };

    componentDidMount = () => {
       // this.getWorklogs();
    };

    getAvailabilities = (name) => { 
        axios({
            url: "/api/employees/name/"+name,
            method: 'get',         
        }).then(response => {
            return response.data.availability;

        }).catch((error) => {
            console.log(error);
        });
    };


    getWorklogs = () => { 
        this.setState({searching:true});
        var d = new Date(this.state.fromDate);
        d.setDate(d.getDate() - 14);
        var month = (parseInt(d.getMonth())<9)?"0"+(d.getMonth()+1).toString():(d.getMonth()+1).toString();
        var day = (parseInt(d.getDate())<10)?"0"+d.getDate().toString():d.getDate().toString();
        var dateFrom = d.getFullYear()+"-"+month+"-"+day;
        axios({
            url: "/api/jiraRest/estimatedTime?username=u&startDate="+this.state.fromDate+"&endDate="+this.state.toDate,
            method: 'get',         
        }).then(response => {

            const updatedEmployee = response.data.map(emp => {
                const matchingEmployee14 = this.state.employee14.find(e => e.name === emp.name);
                if (matchingEmployee14) {
                    return {
                        ...emp,
                        last14: matchingEmployee14.hoursPlanned,
                    };
                }
                return emp;
            });

            this.setState({employee: updatedEmployee});
            this.setState({searching:false});
            
        }).catch((error) => {
            console.log(error);
        });
    };

    
    getWorklogsLastSprint = () => { 
        this.setState({searching:true});
        var d = new Date(this.state.fromDate);
        d.setDate(d.getDate() - 14);
        var month = (parseInt(d.getMonth())<9)?"0"+(d.getMonth()+1).toString():(d.getMonth()+1).toString();
        var day = (parseInt(d.getDate())<10)?"0"+d.getDate().toString():d.getDate().toString();
        var dateFrom = d.getFullYear()+"-"+month+"-"+day;

        var dTo = new Date(this.state.toDate);
        dTo.setDate(dTo.getDate() - 14);
        var monthTo = (parseInt(dTo.getMonth())<9)?"0"+(dTo.getMonth()+1).toString():(dTo.getMonth()+1).toString();
        var dayTo = (parseInt(dTo.getDate())<10)?"0"+dTo.getDate().toString():dTo.getDate().toString();
        var dateTo = dTo.getFullYear()+"-"+monthTo+"-"+dayTo;

    
        axios({
            url: "/api/jiraRest/estimatedTime?last=1&username=u&startDate="+dateFrom+"&endDate="+dateTo,
            method: 'get',         
        }).then(response => {
            this.setState({employee14: response.data});
            console.log(this.state.employee14);
            this.getWorklogs();
        }).catch((error) => {
            console.log(error);
        });
    };

    render() {
        return (
            <div style={{background:"white", borderRadius:"3px 3px 3px 3px", padding: "15px"}}>
                
                 <div style={{ marginBottom: "45px", paddingLeft:"5px"}}>
                <TextField
                    id="date"
                    label="From"
                    type="date"
                    defaultValue={this.state.fromDate}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e)=>this.setState({fromDate: e.target.value})}
                />
                <TextField
                    id="date"
                    label="To"
                    type="date"
                    defaultValue=""
                    InputLabelProps={{
                    shrink: true,
                    }}
                    style={{ paddingLeft:"10px"}}
                    onChange={(e)=>this.setState({toDate: e.target.value})}
                />
                {(!this.state.searching)?(
                     <Button id="date"
                     label="Search"
                     defaultValue=""
                     style={{ paddingLeft:"10px"}}
                     onClick={()=>this.getWorklogsLastSprint()}
                 >Search</Button>
                ):(<span>  Searching...</span>)}
               
                </div>
                <Table>
                        <TableHead>
                        <TableRow >
                            <TableCell>Employee</TableCell>
                            <TableCell numeric>Hours</TableCell>
                            <TableCell numeric>Availability</TableCell>
                            <TableCell numeric>Difference</TableCell>
                            <TableCell numeric>Last Sprint</TableCell>
                            <TableCell numeric>Projects</TableCell>
                            <TableCell numeric>Note</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.employee.map((e, index) => (
                            <TableRow key={e.id} hover>
                                <TableCell component="th" scope="row">
                                {e.name}
                                </TableCell>
                                <TableCell numeric>{e.hoursPlanned}</TableCell>
                                <TableCell numeric>{e.hoursAvail}</TableCell>
                                <TableCell numeric>{e.hoursAvail-e.hoursPlanned}</TableCell>
                                <TableCell numeric>{e.last14}</TableCell>
                                <TableCell numeric>{e.projects}</TableCell>
                                <TableCell numeric>{e.warn=="!" && 
                                <Delete style={{color:"red"}} />
                                }</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
            </div>
        );
    }
}


Jira.propTypes = {
    ...propTypes,
    push: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

Jira.defaultProps = {
    theme: {},
};


export default (Jira);
