import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  DateField,
  EditButton,
  TextInput,
  Show,
  SimpleShowLayout,
  CardActions,
  ListButton,
  Filter,
  BooleanInput,
  BooleanField
} from "react-admin";
import {
    required,
} from "../../validators/validators";

const CustomerFilter = props => (
  <Filter {...props}>
    <TextInput
      elStyle={{ width: 380 }}
      label="resources.misc.fields.name"
      source="name"
    />
  </Filter>
);

export const CustomerList = props => (
  <List {...props} filters={<CustomerFilter />}  bulkActionButtons={false}>
    <Datagrid>
      <TextField label="Vasco ID" source="vasco" />
      <TextField label="Name" source="companyName" />
      <TextField label="Address" source="address" />
      <TextField label="City" source="city" />
      <TextField label="Registration Number" source="registrationNumber" />
      <TextField label="Vat" source="vat" />
      <TextField label="TRR" source="trr" />
      <TextField label="Email" source="email" />
      <TextField label="Invoice Email" source="emailinvoice" />
      <TextField label="Comment Email" source="emailcomment" />
      <BooleanField label="Aboard" source="aboard" />
      <BooleanField label="Aboard EU" source="aboardEu" />
      <EditButton label="" /> 
    </Datagrid>
  </List>
);

const CustomerTitle = ({ record }) => {
    return (
      <span>{record ? `"${record.name}"` : ""}</span>
    );
};

const cardActionStyle = {
    zIndex: 2,
    display: "inline-block",
    float: "right"
};

const Actions = ({ basePath, data, refresh }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
  </CardActions>
);

export const CustomerShow = props => (
  <Show 
  title={<CustomerTitle />} 
  actions={<Actions />}
  {...props}>
    <SimpleShowLayout>
    <TextField label="Vasco" source="vasco" />
      <TextField label="Name" source="companyName" />
      <TextField label="Address" source="address" />
      <TextField label="City" source="city" />
      <TextField label="ZIP" source="email" />
      <TextField label="Registration Number" source="registrationNumber" />
      <TextField label="VAT" source="vat" />
      <TextField label="TRR" source="trr" />
      <TextField label="Email invoice" source="emailinvoice" />
      <TextField label="Email comment" source="emailcomment" />
      <BooleanField label="Aboard" source="aboard" />
      <BooleanField label="Aboard EU" source="aboardEu" />
    </SimpleShowLayout>
  </Show>
);

export const CustomerEdit = ({ permissions, ...props }) => (
  <Edit title={<CustomerTitle />} actions={<CardActions />} {...props}>
    <SimpleForm>
    <TextInput label="Vasco" source="vasco" />
    <TextInput label="Name" source="companyName" />
      <TextInput label="Address" source="address" />
      <TextInput label="City" source="city" />
      <TextInput label="ZIP" source="email" />
      <TextInput label="Registration Number" source="registrationNumber" />
      <TextInput label="VAT" source="vat" />
      <TextInput label="TRR" source="trr" />
      <TextInput label="Email invoice" source="emailinvoice" />
      <TextInput label="Email comment" source="emailcomment" />
      <TextInput source="valuta" />
      <BooleanInput label="Aboard" source="aboard" />
      <BooleanInput label="Aboard EU" source="aboardEu" />
    </SimpleForm>
  </Edit>
);

export const CustomerCreate = ({ permissions, ...props }) => (
  <Create title="Create a Customer" {...props}>
    <SimpleForm redirect="list">
    <TextInput label="Vasco" source="vasco" />
    <TextInput label="Name" source="companyName" />
      <TextInput label="Address" source="address" />
      <TextInput label="City" source="city" />
      <TextInput label="ZIP" source="email" />
      <TextInput label="Registration Number" source="registrationNumber" />
      <TextInput label="VAT" source="vat" />
      <TextInput label="TRR" source="trr" />
      <TextInput label="Email invoice" source="emailinvoice" />
      <TextInput label="Email comment" source="emailcomment" />
      <TextInput source="valuta" />
      <BooleanInput label="Aboard" source="aboard" />
      <BooleanInput label="Aboard EU" source="aboardEu" />
    </SimpleForm>
  </Create>
);